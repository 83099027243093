import './inputField.style.scss';

const Select = ({labelName, inputName, inputValue, onChange, style, required, className, id, options}) => {
    return (
        <>
            <select id={id} name={inputName} value={inputValue} className={"px-2.5 py-2.5 bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 " + className} onChange={onChange}>
                {options.map((option, index) => (
                    <option className="text-black" key={index} value={option.value} disabled={option.disabled} >
                        {option.label}
                    </option>
                ))}
            </select>
        </>
    );
    }

Select.defaultProps = {
    required: false
}

export default Select;