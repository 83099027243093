import './inputField.style.scss';

const InputField = ({ type, labelName, inputName, inputValue, onChange, style, required, checked, className, id }) => {

    return (
        <div className="relative">
            <input type={type} name={inputName} defaultValue={inputValue} onChange={onChange} required={required} id={id} className={'block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer ' + className} placeholder=" " />
            <label htmlFor={id} className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{labelName}</label>
        </div>
    );
}

InputField.defaultProps = {
    required: false
}

export default InputField;