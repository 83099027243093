import { ApiUrl } from '../../API/Api.config';
import { useForm } from "react-hook-form";
import { useEffect, useState } from 'react'
import TokenService from '../../API/token.service';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import MyMapComponent from '../../components/MapContainer/MapContainer';
import PieChart from '../../components/PieChart/PieChart';
import Sidebar from '../../components/Sidebar/Sidebar';
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate } from "react-router-dom";
import { imgApi } from '../../API/Api.config';
import axios from 'axios';
import hero from '../../assets/images/users/cover.jpg';
import PureModal from 'react-pure-modal';
import ReactStars from "react-rating-stars-component";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import './UserInfo.style.scss';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tabs } from 'flowbite-react'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

const UserInfo = () => {
    document.title = "My Profile | Promote"

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    const token = TokenService.getLocalAccessToken()
    const [data, setData] = useState([])
    const role = secureLocalStorage.getItem("role");
    const profileCompleted = secureLocalStorage.getItem('profile_completed')
    const user_id = secureLocalStorage.getItem("user_id");
    const profile = secureLocalStorage.getItem("profile_completed");
    const name = secureLocalStorage.getItem("username");
    const nav = useNavigate()
    const [notifications, setNotifcations] = useState([])
    const [images, setImages] = useState([])
    const [social, setSocial] = useState([])
    const [review, setReview] = useState([]);
    const [socialAudience, setSocialAudience] = useState([]);
    const [socialAudienceCount, setSocialAudienceCount] = useState([]);
    const [socialAudienceLocation, setSocialAudienceLocation] = useState({});
    const MySwal = withReactContent(Swal)


    async function getUserInfo() {
        try {
            await axios.get(ApiUrl + 'user_info/', {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
            })
                .then(response => {
                    // if (!response.data.length && role === 1) {
                    //     nav('/influencer/onboarding/')
                    // } else if (!response.data.length && role === 2) {
                    //     nav('/startup/onboarding/')
                    // }
                    setData(response.data)
                    response.data[0]['social_media_audience'].forEach((sma) => {
                        setSocialAudience(prevState => [...prevState, sma.category])
                        setSocialAudienceCount(prevState => [...prevState, 1])
                        setSocialAudienceLocation({ lat: Number(response.data[0].audience_geolocation.split(',')[0]), lng: Number(response.data[0].audience_geolocation.split(',')[1]) })
                    })
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function getUserReview() {
        try {
            await axios.get(ApiUrl + `influencer_review/?id=${user_id}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
            })
                .then(response => {
                    // console.log(response.data)
                    setReview(response.data)
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function getSocial() {
        try {
            await axios.get(ApiUrl + 'update_social/', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    setSocial(response.data)

                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {

        }
    }

    async function getUserImages() {
        try {
            await axios.get(ApiUrl + `influencer_images/`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setImages(response.data); })
                .catch(error => console.log(error))
        } catch (e) {
            console.log()
        }
    }

    async function getStartupImages() {
        try {
            await axios.get(ApiUrl + `startup_images/`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setImages(response.data); })
                .catch(error => console.log(error))
        } catch (e) {
            console.log()
        }
    }

    async function getUserNotifications() {
        try {
            await axios.get(ApiUrl + 'notification_system/', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => { setNotifcations(response.data); })
                .catch(error => console.log(error))
        } catch (e) {
            console.log()
        }
    }

    async function getStartupInfo() {
        try {
            await axios.get(ApiUrl + 'startup_info/', {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
            })
                .then(response => setData(response.data))
                .catch(error => {
                    console.log(error.response.status)
                    if (error.response.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log()
        }
    }

    const handleClick = (img) => {
        Swal.fire({
            imageUrl: imgApi + img,
            imageAlt: 'Custom image',
            width: 500,
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                image: 'img-pop-up',
                popup: 'html-img'
            }
        })
    }


    function addHttpsToUrl(url) {
        if (!/^https:\/\//i.test(url)) {
            url = "https://" + url;
        }
        return url;
    }

    const extractUsernameFromUrl = (url) => {
        const regex = {
            instagram: /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)/,
            facebook: /(?:http(?:s)?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9\.]+)/,
            tiktok: /(?:http(?:s)?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9_\.]+)/,
            linkedin: /(?:http(?:s)?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9\-\.]+)/,
            youtube: /(?:http(?:s)?:\/\/)?(?:www\.)?youtube\.com\/(?:channel\/)?([a-zA-Z0-9_\-]+)/
        };

        let username = null;
        Object.keys(regex).some((platform) => {
            const match = url.match(regex[platform]);
            if (match) {
                username = match[1];
                return true;
            }
        });

        return username;
    };

    const createSocialMediaUrl = (platform, username) => {
        switch (platform) {
            case 'instagram':
                return `https://www.instagram.com/${username}`;
            case 'facebook':
                return `https://www.facebook.com/${username}`;
            case 'tiktok':
                return `https://www.tiktok.com/@${username}`;
            case 'linkedin':
                return `https://www.linkedin.com/in/${username}`;
            case 'youtube':
                return `https://www.youtube.com/channel/${username}`;
            default:
                return null;
        }
    };

    function getSocialMediaColor(platformName) {
        let color;
        switch (platformName.toLowerCase()) {
            case 'instagram':
                color = '#C13584'; // pink
                break;
            case 'facebook':
                color = '#1877F2'; // blue
                break;
            case 'tiktok':
                color = '#000000'; // black
                break;
            case 'linkedin':
                color = '#0077B5'; // blue
                break;
            case 'youtube':
                color = '#FF0000'; // red
                break;
            default:
                color = '#000000'; // black
        }
        return color;
    }


    useEffect(() => {
        if (role === 1) {
            getUserInfo()
            getSocial()
            getUserImages()
            getUserNotifications()
            getUserReview()
        } else {
            getUserNotifications()
            getStartupInfo()
            getStartupImages()
            getSocial()
        }

    }, [])

    // useEffect(() => {
    //     console.log(notifications)
    // }, [notifications])

    useEffect(() => {
        console.log(socialAudienceCount)
    }, [socialAudienceCount]);


    useEffect(() => {
        if (!profileCompleted) {
            if (role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" className="bg-secondary tex text-green-500t-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            } else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" className="bg-secondary tex text-green-500t-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }
        }
    }, [])


    return (
        <>
            <DashboardHeader />
            <Sidebar />
            <div className='sm:ml-64 ml-0 mt-20 pb-10 pt-15 user-info'>
                <div className='container mx-auto h-full'>
                    {role == 1 ?
                        <>
                            <div className='h-full'>
                                {data.map((res) => {
                                    return (
                                        <>
                                            <div className='user-details w-full flex justify-center items-center h-full'>
                                                <div className='grid sm:grid-cols-6 sm:grid-rows-1 grid-rows-10 grid-cols-1 gap-y-4 w-full h-full'>
                                                    <div className='bg-white flex items-center justify-center p-4 rounded-l-lg sm:col-span-1 col-span-6 sm:row-span-1'>
                                                        <div className='basic-info flex flex-col items-center justify-center w-full'>
                                                            <div className='profile-img '>
                                                                <img src={imgApi + res.profile_image} alt={res.profile_image} className="border border-primary rounded-full" />
                                                            </div>
                                                            <div>
                                                                <p className='mt-4 text-secondary font-bold text-center'>
                                                                    {res.first_name ? res.first_name : res.name}
                                                                </p>
                                                                <p className=' text-primary italic text-center'>
                                                                    Influencer
                                                                </p>
                                                            </div>
                                                            <Link to="/update-user-profile/" className="w-full p-1 text-white rounded-lg bg-primary mt-4 text-center" state={{
                                                                profile_image: imgApi + res.profile_image,
                                                                name: res.name,
                                                                bio: res.bio,
                                                                age: res.age,
                                                                career: res.career,
                                                                price_per_post: res.price_per_post,
                                                                charitable: res.charitable,
                                                                gender: res.gender,
                                                                location: res.location,
                                                                education_location: res.education_location,
                                                                interest: res.interest,
                                                                payment_type: res.payment_type,
                                                                show_payment_type: res.show_payment_type,
                                                                followers_number: res.followers_number,
                                                                social_media_audience: res.social_media_audience,
                                                                type_of_collaboration: res.type_of_collabration,
                                                                audience_location: res.audience_location,
                                                            }}>
                                                                <i className="fa-solid fa-pen"></i>&nbsp;Edit Profile
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className='sm:col-span-5 col-span-6 sm:row-span-1 h-full'>
                                                        <div className='bg-white p-4 rounded-r-lg h-full'>
                                                            <div className='flex justify-end flex-col h-full'>
                                                                <div className=''>
                                                                    <p className='text-sm'>
                                                                        <i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{res.location}
                                                                    </p>
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <p className='text-sm'>
                                                                        <i className="fa-solid fa-graduation-cap"></i>&nbsp;&nbsp;{res.education_location ? res.education_location : 'Not Available'}
                                                                    </p>
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <p className='text-sm'>
                                                                        <i className="fa-solid fa-suitcase"></i>&nbsp;&nbsp;{res.career ? res.career : 'Not Available'}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className='text-sm mt-2 leading-6' dangerouslySetInnerHTML={{ __html: res.bio }} >
                                                                    </p>
                                                                </div>
                                                                <div className='flex sm:justify-between sm:flex-row flex-col'>
                                                                    <div>
                                                                        {social.length > 0 ?
                                                                            <div className={'grid grid-cols-5 justify-items-center mt-2'}>
                                                                                {Object.keys(social[0]).map((oneKey, i) => {
                                                                                    if (oneKey !== 'id') {
                                                                                        if ((social[0][oneKey] !== '') && (social[0][oneKey] !== null)) {
                                                                                            if (social[0][oneKey]?.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() !== 'NA'.toLowerCase()) {
                                                                                                if (social[0][oneKey].toLowerCase().includes(oneKey + ".com") === true) {
                                                                                                    return (
                                                                                                        <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{ background: getSocialMediaColor(oneKey) }}>
                                                                                                            <a href={createSocialMediaUrl(oneKey, extractUsernameFromUrl(social[0][oneKey].toLowerCase().replace('@', '')))} target="_blank" className='text-center text-xs w-full block'>
                                                                                                                <i className={"fa-brands fa-" + oneKey + " text-white"}></i>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{ background: getSocialMediaColor(oneKey) }}>
                                                                                                            <a href={createSocialMediaUrl(oneKey, social[0][oneKey]).replace('@', '')} target="_blank" className='text-center text-xs w-full block'>
                                                                                                                <i className={"fa-brands fa-" + oneKey + " text-white"} ></i>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            } else {
                                                                                                return (
                                                                                                    <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-gray-500 rounded-lg w-full border hover:border-secondary">
                                                                                                        <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                            <i className={"fa-brands fa-" + oneKey + " text-white"}></i>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        } else {
                                                                                            return (
                                                                                                <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-gray-500 rounded-lg w-full border hover:border-secondary">
                                                                                                    <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                        <i className={"fa-brands fa-" + oneKey + " text-white"}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            </div> :
                                                                            <>
                                                                                <div className='mt-2 bg-secondary rounded-lg p-2 px-4 '>
                                                                                    <p className='text-white text-sm'>
                                                                                        No Social Media Accounts Found
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <button className='bg-secondary text-xs p-2 rounded-lg text-white w-full sm:w-auto'>
                                                                            <Link to="/update-social/" state={{
                                                                                social: social
                                                                            }}>
                                                                                Manage Social Media
                                                                            </Link>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-span-6 row-span-2'>
                                                        <div className='grid grid-cols-6 grid-rows-1 gap-4 '>
                                                            <div className='sm:col-span-2 col-span-6 row-span-1 h-full'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <h3 className='font-bold'>
                                                                        Basic Information
                                                                    </h3>
                                                                    <div className='mt-4 rounded-lg'>
                                                                        <table className="w-full rounded-lg text-xs text-left text-gray-500">
                                                                            <tbody>
                                                                                <tr className="bg-white border-b">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Age:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.age ? res.age : 'NA'}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="bg-white border-b">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Gender:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.gender ? <span className='capitalize'>{res.gender}</span> : 'NA'}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="bg-white border-b">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Charitable:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.charitable ? 'Yes' : 'No'}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="bg-white border-b">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Price Per Post:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        £ {res.price_per_post ? res.price_per_post.toLocaleString() : 'NA'}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="bg-white border-b">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Payment Type:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.callaboration_options.map((payment, key) => (
                                                                                            <span key={key}> {payment.title} {key !== res.callaboration_options.length - 1 ? '/' : ''}</span>
                                                                                        ))}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="bg-white">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Followers Number:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.followers_number ? res.followers_number.toLocaleString() : 'NA'}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='sm:col-span-3 col-span-6 row-span-1 h-full'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <Tabs.Group
                                                                        aria-label="Tabs with underline"
                                                                        style="underline"
                                                                        className='tab-btn'
                                                                    >
                                                                        <Tabs.Item
                                                                            title="Interests"
                                                                            active={true}
                                                                        >
                                                                            <h3 className='font-bold'>
                                                                                Influencer's Interests
                                                                            </h3>
                                                                            <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full interests-card'>
                                                                                {res.interest.length > 0 ?
                                                                                    res.interest.map(obj => {
                                                                                        return (
                                                                                            <div className='rounded-lg interest-img' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + imgApi + obj.image + ")" }}>
                                                                                                <div className='flex justify-center items-center h-full text-white text-center'>
                                                                                                    <p className='font-bold text-xl'>
                                                                                                        {obj.interest}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }) :
                                                                                    <>
                                                                                        <p>No Interests Found</p>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </Tabs.Item>
                                                                        <Tabs.Item
                                                                            title="Promotional Pictures"
                                                                        >
                                                                            <div className='flex flex sm:justify-between sm:flex-row flex-col'>
                                                                                <h3 className='font-bold'>
                                                                                    Influencer's Pictures (<small>Click to view bigger</small>)
                                                                                </h3>
                                                                                <button className='sm:text-xs text-sm sm:px-2 p-1 sm:p-0 rounded-lg bg-primary text-white font-bold w-full sm:w-auto'>
                                                                                    <a href="/upload-promotion-pictures">Manage Your Pictures</a>
                                                                                </button>
                                                                            </div>
                                                                            <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full'>
                                                                                {images.length > 0 ?
                                                                                    images.map(obj => {
                                                                                        return (
                                                                                            <div className='rounded-lg more-pics' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + imgApi + obj.image + ")" }} onClick={() => { handleClick(obj.image) }}></div>
                                                                                        )
                                                                                    }) :
                                                                                    <>
                                                                                        <p> No Pictures Found</p>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </Tabs.Item>
                                                                        <Tabs.Item
                                                                            title=" Reviews"
                                                                        >
                                                                            <div className='flex sm:justify-between sm:flex-row flex-col'>
                                                                                <h3 className='font-bold'>
                                                                                    Influencer's Reviews
                                                                                </h3>
                                                                            </div>
                                                                            <div className='grid grid-cols-3 gap-4 mt-4 h-full'>
                                                                                {review.length > 0 ?
                                                                                    review.map(obj => {
                                                                                        return (
                                                                                            <div className='p-4 rounded-lg border'>
                                                                                                <ReactStars
                                                                                                    count={5}
                                                                                                    value={obj.star}
                                                                                                    size={24}
                                                                                                    edit={false}
                                                                                                    activeColor="#ffd700"
                                                                                                />
                                                                                                <p>
                                                                                                    {obj.review}
                                                                                                </p>
                                                                                            </div>
                                                                                        )
                                                                                    }) :
                                                                                    <>
                                                                                        <p> No Reviews Found</p>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </Tabs.Item>
                                                                    </Tabs.Group>
                                                                </div>
                                                            </div>
                                                            <div className='sm:col-span-1 col-span-6 h-full row-span-1'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <h3 className='font-bold'>
                                                                        Notifications
                                                                    </h3>
                                                                    <div>
                                                                        {notifications.length > 0 ?
                                                                            <>
                                                                                <div className='bg-fourth mt-2 p-2 rounded-lg'>
                                                                                    <a href="/task-center">
                                                                                        <p className='text-sm'>
                                                                                            <b> {notifications.length} new notifications</b>  in Task Center
                                                                                        </p>
                                                                                    </a>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <p className='mt-5'> No New Notifications</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='sm:col-span-3 col-span-6 h-full row-span-1'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <h3 className='font-bold'>
                                                                        Target Audience
                                                                    </h3>
                                                                    <div>
                                                                        <PieChart label={socialAudience} data={socialAudienceCount} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='sm:col-span-3 col-span-6 h-full row-span-1'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <h3 className='font-bold'>
                                                                        Audience Location
                                                                    </h3>
                                                                    <div className='map'>
                                                                        <Wrapper apiKey={"AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw"}>
                                                                            <MyMapComponent center={socialAudienceLocation} zoom={10} />
                                                                        </Wrapper>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <>
                            <div className='h-full'>
                                {data.map((res) => {
                                    return (
                                        <>
                                            <div className='user-details w-full flex justify-center items-center h-full'>
                                                <div className='grid sm:grid-cols-6 grid-rows-2 gap-y-4 w-full'>
                                                    <div className='bg-white flex items-center justify-center p-4 rounded-l-lg sm:col-span-1 col-span-6'>
                                                        <div className='basic-info flex flex-col items-center justify-center w-full'>
                                                            <div className='profile-img '>
                                                                <img src={imgApi + res.logo} alt={res.logo} className="border border-primary rounded-full" />
                                                            </div>
                                                            <div>
                                                                <p className='mt-4 text-secondary font-bold text-center'>
                                                                    {res.name}&nbsp;
                                                                    {res.is_sustainable &&
                                                                        <i className="fa-solid fa-leaf text-green-500"></i>
                                                                    }
                                                                    {res.charitable &&
                                                                        <i className="fa-solid fa-hand-holding-heart text-red-500"></i>
                                                                    }
                                                                </p>
                                                                <p className=' text-primary italic text-center'>
                                                                    Business
                                                                </p>
                                                            </div>
                                                            <Link to="/update-startup-profile/" className="w-full p-1 text-white rounded-lg bg-primary mt-4 text-center" state={{
                                                                charitable: res.charitable,
                                                                bio: res.bio,
                                                                business_type: res.business_type,
                                                                website: res.website,
                                                                location: res.location,
                                                                logo: imgApi + res.logo,
                                                            }}>
                                                                <i className="fa-solid fa-pen"></i>&nbsp;Edit Profile
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className='sm:col-span-5 col-span-6 h-full'>
                                                        <div className='bg-white p-4 rounded-r-lg h-full'>
                                                            <div className='flex justify-end flex-col h-full'>

                                                                <div className=''>
                                                                    <p className='text-sm'>
                                                                        <i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{res.location}
                                                                    </p>
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <p className='text-sm'>
                                                                        <i className="fa-solid fa-globe"></i>&nbsp;&nbsp;<a href={addHttpsToUrl(res.website)} target='_blank'>{res.website}</a>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className='text-sm mt-2 leading-6' dangerouslySetInnerHTML={{ __html: res.bio }}>
                                                                    </p>
                                                                </div>
                                                                <div className='flex flex-col'>
                                                                    <div className='flex sm:justify-between sm:flex-row flex-col'>
                                                                        <div>
                                                                            {social.length > 0 ?
                                                                                <div className={'grid grid-cols-5 justify-items-center mt-2'}>
                                                                                    {Object.keys(social[0]).map((oneKey, i) => {
                                                                                        if (oneKey !== 'id') {
                                                                                            if ((social[0][oneKey] !== '') && (social[0][oneKey] !== null)) {
                                                                                                if (social[0][oneKey]?.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() !== 'NA'.toLowerCase()) {
                                                                                                    if (social[0][oneKey].toLowerCase().includes(oneKey + ".com") === true) {
                                                                                                        return (
                                                                                                            <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{ background: getSocialMediaColor(oneKey) }}>
                                                                                                                <a href={createSocialMediaUrl(oneKey, extractUsernameFromUrl(social[0][oneKey].toLowerCase().replace('@', '')))} target="_blank" className='text-center text-xs w-full block'>
                                                                                                                    <i className={"fa-brands fa-" + oneKey + " text-white"}></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <div key={i} className="p-2 mr-2 flex items-center justify-center h-full rounded-lg w-full border hover:border-secondary" style={{ background: getSocialMediaColor(oneKey) }}>
                                                                                                                <a href={createSocialMediaUrl(oneKey, social[0][oneKey]).replace('@', '')} target="_blank" className='text-center text-xs w-full block'>
                                                                                                                    <i className={"fa-brands fa-" + oneKey + " text-white"} ></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                } else {
                                                                                                    return (
                                                                                                        <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-gray-500 rounded-lg w-full border hover:border-secondary">
                                                                                                            <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                                <i className={"fa-brands fa-" + oneKey + " text-white"}></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            } else {
                                                                                                return (
                                                                                                    <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-gray-500 rounded-lg w-full border hover:border-secondary">
                                                                                                        <span target="_blank" className='text-center text-xs w-full block'>
                                                                                                            <i className={"fa-brands fa-" + oneKey + " text-white"}></i>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    })}
                                                                                </div> :
                                                                                <>
                                                                                    <div className='mt-2 bg-secondary rounded-lg p-2 px-4 '>
                                                                                        <p className='text-white text-sm'>
                                                                                            No Social Media Accounts Found
                                                                                        </p>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        <div className='mt-2'>
                                                                            <button className='bg-secondary text-xs p-2 rounded-lg text-white w-full sm:w-auto'>
                                                                                <Link to="/update-social/" state={{
                                                                                    social: social
                                                                                }}>
                                                                                    Manage Social Media
                                                                                </Link>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-span-6 row-span-1'>
                                                        <div className='grid grid-cols-6 grid-rows-1 gap-4 '>
                                                            <div className='sm:col-span-2 col-span-6 row-span-1 h-full'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <h3 className='font-bold'>
                                                                        Basic Information
                                                                    </h3>
                                                                    <div className='mt-4 rounded-lg'>
                                                                        <table className="w-full rounded-lg text-xs text-left text-gray-500">
                                                                            <tbody>
                                                                                <tr className="bg-white border-b">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Business Type:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.business_type ? res.business_type : 'NA'}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr className="bg-white border-b">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Charitable:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.charitable ? 'Yes' : 'No'}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="bg-white">
                                                                                    <th scope="row" className="py-3 px-2 font-medium text-gray-900 whitespace-nowrap">
                                                                                        Sustainable:
                                                                                    </th>
                                                                                    <td className="py-3 px-2">
                                                                                        {res.is_sustainable ? 'Yes' : 'No'}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='sm:col-span-3 col-span-6 row-span-1 h-full'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <div className='flex sm:justify-between sm:flex-row flex-col'>
                                                                        <h3 className='font-bold'>
                                                                            Additional Pictures (<small>Click to view bigger</small>)
                                                                        </h3>
                                                                        <button className='sm:text-xs text-sm sm:px-2 p-1 sm:p-0 rounded-lg bg-primary text-white font-bold w-full sm:w-auto'>
                                                                            <a href="/upload-promotion-pictures">Manage Your Pictures</a>
                                                                        </button>
                                                                    </div>
                                                                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mt-4 h-full'>
                                                                        {images.length > 0 ?
                                                                            images.map(obj => {
                                                                                return (
                                                                                    <div className='rounded-lg more-pics' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + imgApi + obj.image + ")" }} onClick={() => { handleClick(obj.image) }}></div>
                                                                                )
                                                                            }) :
                                                                            <>
                                                                                <p> No Pictures Found</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='sm:col-span-1 col-span-6 h-full row-span-1'>
                                                                <div className='bg-white p-4 rounded-lg h-full'>
                                                                    <h3 className='font-bold'>
                                                                        Notifications
                                                                    </h3>
                                                                    <div>
                                                                        {notifications.length > 0 ?
                                                                            <>
                                                                                <div className='bg-fourth mt-2 p-2 rounded-lg'>
                                                                                    <a href="/task-center">
                                                                                        <p className='text-sm'>
                                                                                            <b> {notifications.length} new notifications</b>  in Task Center
                                                                                        </p>
                                                                                    </a>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <p className='mt-5'> No New Notifications</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default UserInfo;