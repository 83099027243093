import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../API/Api.config';
import { useForm } from 'react-hook-form';
import secureLocalStorage from "react-secure-storage";
import TokenService from '../../API/token.service';
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import './Terms.style.scss';

export default function Payment() {
    document.title = "Pricing Plans | Promote"

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    // const { setValue, handleSubmit, formState: { error } } = useForm();

    const nav = useNavigate()
    const token = TokenService.getLocalAccessToken()
    const [sessionID, setSessionID] = useState("");
    const [data, setData] = useState([]);
    const [freePackage, setFreePackage] = useState([]);
    const [pay, setPay] = useState(undefined)
    const [plan, setPlan] = useState(null);
    const date = new Date();
    const getDay = date.getDate();
    const getMonth = date.getMonth() + 1;
    const getYear = date.getFullYear();
    const fullDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (getDay <= 9 ? '0' + getDay : getDay);



    return (
        <>
            <Header />
            <div className='container'>
                    <p
                        className="s1"
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            marginTop: "50px"
                        }}
                    >
                        Influencer T&amp;Cs
                    </p>
                    <ol id="l1">
                        <ol id="l2">
                            <li data-list-text="1.1">
                                <p
                                    style={{
                                        paddingTop: "15pt",
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    We are REACH.IO LTD a company registered in England and Wales, trading
                                    as PROMOTE Our company registration number is 13813664 and our
                                    registered office is at 13 Queens Road, Bournemouth BH2 6BA
                                </p>
                                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                    Our platform ("Platform") enables Influencers registered on the
                                    Platform ("you", "your" or "Influencers") to be contacted by
                                    businesses (“Businesses”) registered on the Platform giving you the
                                    opportunity to offer them your services.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text="1.2">
                                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                    By ticking "Accept" where indicated, applying for an account on the
                                    Platform ("Influencer Account") or by using the Platform, you agree
                                    that you shall be bound by these terms and conditions
                                </p>
                                <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                    ("Terms") in consideration of and in relation to your use of the
                                    Platform, our provision of it and our provision of the services and
                                    functionality as set out in these Terms ("Services"). Please read
                                    these Terms carefully as they contain important information about your
                                    rights and responsibilities when using the Platform and Services.
                                </p>
                            </li>
                            <li data-list-text="1.3">
                                <p
                                    style={{
                                        paddingTop: "15pt",
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    In consideration of us providing the Services and making the Platform
                                    available to you, you provide us details when applying for an
                                    Influencer Account which we may publish on the Platform.
                                </p>
                            </li>
                        </ol>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <ol id="l3">
                        <li data-list-text={2}>
                            <p
                                style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}
                            >
                                PLATFORM
                            </p>
                            <ol id="l4">
                                <li data-list-text="2.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        In order to use the Platform and the Services, and when applying for
                                        an Influencer Account, you will be required to provide us with
                                        certain details, as indicated on the Platform. We shall be under no
                                        obligation to accept an application from you and any application
                                        shall only be accepted following our written confirmation that the
                                        application has been successful. You must treat your login details
                                        (including any password and security question answers) as
                                        confidential and not disclose them to any third party.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="2.2">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You may also be placed on a waiting list which shall not be
                                        construed as a rejection or acceptance of the offer, instead we may
                                        confirm in writing acceptance of your offer has been successful at
                                        some point in the future. Following our acceptance of your
                                        application, your Influencer Account will become live and a contract
                                        is formed between you and us, which shall be subject to these Terms
                                        ("Contract").
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="2.3">
                                    <p
                                        style={{
                                            paddingLeft: "26pt",
                                            textIndent: "-20pt",
                                            lineHeight: "15pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        The Platform facilitates the connection of Businesses with
                                    </p>
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        Influencers. Businesses have the opportunity to view the profiles of
                                        and select Influencers on our Platform to assist with the promotion
                                        of their commercial activities. Influencers have the ability to
                                        showcase their capabilities on our
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Platform. All arrangements between Businesses and Influencers shall
                                        take place outside of our Platform. If you choose to provide your
                                        services to the Businesses, you agree that you will enter into a
                                        separate agreement with the Businesses and invoice the Businesses
                                        directly for your services. You agree that we are not responsible
                                        for and shall not be liable to you, or to any third party, in
                                        relation to your arrangement or agreement with the Businesses, or
                                        for any acts or omissions of the Businesses, including non-payment
                                        of any invoices. We will not carry out any checks in relation to the
                                        Businesses and shall in no way be responsible or liable for their
                                        acts or omissions or for any damage or problem caused by them. You
                                        must conduct your own due diligence and enquiries before entering
                                        into any agreement with the Businesses. You accept all risks in
                                        relation to the Businesses and your agreements with the Businesses.
                                    </p>
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        2.3.2 You acknowledge and agree that we may use your existing social
                                        media content, while crediting your name, on our social media
                                        accounts to mention you as part of our community.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={3}>
                            <p
                                style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}
                            >
                                YOUR OBLIGATIONS
                            </p>
                            <ol id="l5">
                                <li data-list-text="3.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        You agree that all information you submit when creating your
                                        InfluencerAccount or otherwise make available through the Platform
                                        is accurate, complete and not misleading, and that you shall
                                        immediately inform us of any changes or inaccuracies to such
                                        information.
                                    </p>
                                </li>
                                <li data-list-text="3.2">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        You represent and warrant on an on-going basis that your use of the
                                        Services and Platform and each of your Content does not infringe the
                                        rights of any other person or body and complies with all applicable
                                        laws, regulations, codes and standards, including without limitation
                                        all applicable advertising standards and regulations concerning
                                        sponsored or advertorial content. Such obligation includes but is
                                        not limited to a responsibility on you to ensure that any Content
                                        includes the necessary hash tag requirements that exist in respect
                                        of all applicable advertising standards and regulations.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="3.3">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You agree to promptly provide us with all such assistance and
                                        information in relation to the Services as we may reasonably request
                                        from time to time.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="3.4">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You agree that you shall not do any of the following without our
                                        prior written consent, either solely or jointly with or on behalf of
                                        any third party directly or indirectly:
                                    </p>
                                    <ol id="l6">
                                        <li data-list-text="3.4.1">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                reproduce or copy information or material from the Platform or
                                                any other material sent or made available to you by us or by the
                                                Businesses ("Material") or create derivative works from, modify
                                                or in any way commercially exploit any of the Material;
                                            </p>
                                        </li>
                                        <li data-list-text="3.4.2">
                                            <p
                                                style={{
                                                    paddingTop: "3pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                distribute, transmit or publish any of the Material (including
                                                using it as part of any library, archive or similar service); or
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.4.3">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                create a database in electronic or structured manual form by
                                                downloading and storing all or any of the Material for any
                                                purpose whatsoever.
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="3.5">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        Except as expressly set out in these Terms you undertake that you
                                        shall not either solely or jointly with or on behalf of any third
                                        party directly or indirectly:
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <ol id="l7">
                                        <li data-list-text="3.5.1">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                attempt to copy, duplicate, adapt, modify, create derivative
                                                works from or distribute all or any portion of the Platform or
                                                the Services;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.2">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                rent, lease, sub-license, assign, sell, encumber, loan,
                                                translate, merge, adapt, vary or modify the Platform or the
                                                Services;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.3">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                attempt to re-use, reproduce, reverse compile, disassemble,
                                                reverse engineer, attempt to discern any software, including
                                                source code, used by or otherwise relating to the Platform or
                                                the Services or otherwise reduce to human-perceivable form all
                                                or any part of the Platform or the Services;
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.4">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                use the Platform or the Services on behalf of any other party
                                                nor allow or permit a third party to do so or to have access to
                                                the Platform or the Services;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.5">
                                            <p
                                                style={{
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                do (or by omission do) anything which may damage our reputation;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.6">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                access, store, distribute or transmit any viruses, during the
                                                course of your use of the Platform or the Services;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.7">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                remove or alter any copyright or other proprietary notice on any
                                                of the Platform or the Services;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.8">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                access or use all or any part of the Platform or the Services in
                                                order to build a product or service which competes with the
                                                Platform or the Services; or
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.5.9">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                impersonate another person or use a name you are unauthorised to
                                                use or try to mislead others as to your identity.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="3.6">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        You undertake that you shall not access, store, distribute or
                                        transmit any material during your access to the Platform or the
                                        Services, in relation to your Content or in relation to your
                                        relationship with Us or the Businesses that:
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <ol id="l8">
                                        <li data-list-text="3.6.1">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                is unlawful, harmful, threatening, defamatory, obscene,
                                                infringing, harassing or racially or ethnically offensive;
                                            </p>
                                        </li>
                                        <li data-list-text="3.6.2">
                                            <p
                                                style={{
                                                    paddingTop: "3pt",
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                facilitates illegal activity;
                                            </p>
                                        </li>
                                        <li data-list-text="3.6.3">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                depicts sexually explicit images;
                                            </p>
                                        </li>
                                        <li data-list-text="3.6.4">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                promotes unlawful violence;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.6.5">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                is discriminatory based on race, gender, colour, religious
                                                belief, sexual orientation, disability; or
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="3.6.6">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                in manner or content is otherwise illegal or causes damage or
                                                injury to any person or property.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="3.7">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        In the event of a breach by you of clauses 3.5 or 3.6 we reserve the
                                        right, without liability or prejudice to our other rights under
                                        these Terms, to:
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <ol id="l9">
                                        <li data-list-text="3.7.1">
                                            <p
                                                style={{
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                disable your access to the Platform or the Services; or
                                            </p>
                                        </li>
                                        <li data-list-text="3.7.2">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                remove from the Platform any Content that we believe, acting
                                                reasonably breaches clause 3.6, and in such circumstances no
                                                compensation will be payable to you.
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="3.8">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You must have a valid and live account for those social media
                                        platforms that you register when creating your Influencer Account.
                                        Your social media platform accounts and your use of them, or any of
                                        the services provided by the relevant social media platform will be
                                        governed by their terms of use. When using each applicable social
                                        media platform, you must do so in accordance with their terms of
                                        use.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={4}>
                            <p
                                style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}
                            >
                                OUR RESPONSIBILITIES
                            </p>
                            <ol id="l10">
                                <li data-list-text="4.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        We shall have no responsibility for any act or omission of any
                                        Businesses in any way whatsoever, including their compliance with
                                        the processes set out in these Terms.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="4.2">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        We do not warrant that the use of the Services or the Platform will
                                        be uninterrupted or error-free and you acknowledge that we may make
                                        changes to the Platform, or suspend or terminate the Platform or
                                        Services or your access to them for any reason without notice to
                                        you.
                                    </p>
                                </li>
                                <li data-list-text="4.3">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        We do not guarantee that the Services or the Platform will be secure
                                        or free from bugs or viruses. You are responsible for configuring
                                        your information technology, mobile device, computer programs and
                                        platform in order to access the Platform.
                                    </p>
                                </li>
                                <li data-list-text="4.4">
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        We assume no responsibility and shall not be liable for any loss or
                                        damage caused by a virus, or other technologically harmful material
                                        that may infect your computer or other equipment or other property
                                        on account of your access to, use of, or browsing of the Platform or
                                        any website linked to it.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={5}>
                            <p
                                style={{
                                    paddingTop: "15pt",
                                    paddingLeft: "19pt",
                                    textIndent: "-13pt",
                                    textAlign: "left"
                                }}
                            >
                                INTELLECTUAL PROPERTY &amp; DATA PROTECTION
                            </p>
                            <ol id="l11">
                                <li data-list-text="5.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        We, and / or our licensors, own the copyright and all other
                                        intellectual property rights (including but not limited to
                                        copyright, database rights, design rights (registered and
                                        unregistered) and trade marks) ("Intellectual Property Rights") in
                                        the Platform and the Services and your only right to use such rights
                                        is as set out in these Terms, any other use by you is prohibited.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="5.2">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You hereby grant to us a perpetual, non-exclusive, irrevocable,
                                        sub-licensable, royalty-free licence to use, for promotional and
                                        marketing purposes, all content and materials and all Intellectual
                                        Property Rights and all other rights in:
                                    </p>
                                    <ol id="l12">
                                        <li data-list-text="5.2.1">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                all information that you upload in connection with this Contract
                                                and any other social media content that you upload directly to
                                                your social media accounts; and
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="5.2.2">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                any other details and information you give to us during your use
                                                of the Platform and Services, and you warrant that our use of
                                                the licence in this clause 6.3 shall not infringe the
                                                Intellectual Property Rights or other rights of any third party.
                                                This licence shall survive termination of the Contract.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="5.3">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Our privacy policy contains details of how we use the information we
                                        hold about you and can be found on the Platform or such other
                                        website address as may be notified to you from time to time. You
                                        agree we can use such information in accordance with our privacy
                                        policy.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="5.4">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        If we process any personal data on your behalf when performing our
                                        obligations under the Terms, you and we record our intention that
                                        (save as set out in our privacy policy) you shall be the data
                                        controller and we shall be a data processor and in any such case:
                                    </p>
                                    <ol id="l13">
                                        <li data-list-text="5.4.1">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                you acknowledge and agree that the personal data may be
                                                transferred or stored outside the EEA or the country where you
                                                are located in order to carry out the Services and our other
                                                obligations under the Terms;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="5.4.2">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "3pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                you shall ensure that you are entitled to transfer the relevant
                                                personal data to us so that we may lawfully use, process and
                                                transfer the personal data in accordance with these Terms on
                                                your behalf;
                                            </p>
                                        </li>
                                        <li data-list-text="5.4.3">
                                            <p
                                                style={{
                                                    paddingTop: "3pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "justify"
                                                }}
                                            >
                                                you shall ensure that the relevant third parties have been
                                                informed of, and have given their consent to, such use,
                                                processing, and transfer as required by all applicable data
                                                protection legislation; and
                                            </p>
                                        </li>
                                        <li data-list-text="5.4.4">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                each party shall take appropriate technical and organisational
                                                measures against unauthorised or unlawful processing of the
                                                personal data or its accidental loss, destruction or damage.
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={6}>
                            <p
                                style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}
                            >
                                TERMINATION
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l14">
                                <li data-list-text="6.1">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        We may terminate the Contract and/or deactivate your
                                        InfluencerAccount immediately for any reason without giving you
                                        notice.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="6.2">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You can terminate the Contract for any reason on giving us 1 months
                                        written notice.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="6.3">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You may terminate the Contract with us if we have committed a
                                        material breach of these Terms and fail to rectify such breach
                                        within 30 days of notice from you and provided that in such notice
                                        you clearly indicate your intention to terminate the Contract with
                                        us if we fail to rectify the breach.
                                    </p>
                                </li>
                                <li data-list-text="6.4">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Termination of the Contract, however arising, shall not affect or
                                        prejudice the accrued rights of us or you as at termination or the
                                        continuation of any provision expressly or by implication intended
                                        to survive termination.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="6.5">
                                    <p
                                        style={{
                                            paddingLeft: "26pt",
                                            textIndent: "-20pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        On termination of the Contract (for any reason):
                                    </p>
                                    <ol id="l15">
                                        <li data-list-text="6.5.1">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                you shall () stop all use of the Platform and Services;
                                            </p>
                                        </li>
                                        <li data-list-text="6.5.2">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                the accrued rights of us or you as at termination or the
                                                continuation of any provision expressly or by implication
                                                intended to survive termination shall not be affected or
                                                prejudiced; and
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="6.5.3">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                we shall have the right to delete any or all of the information
                                                you have uploaded to the Platform during the term of the
                                                Contract.
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={7}>
                            <p
                                style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}
                            >
                                CONFIDENTIALITY
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l16">
                                <li data-list-text="7.1">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You undertake that you shall keep secure and not at any time
                                        disclose to any person any information relating to us or any of the
                                        Businesses which we reasonably consider to be confidential,
                                        including but not limited to details of our relationships with or
                                        knowledge of the requirements of the Businesses; details of our, or
                                        the Businesses business methods, finances, prices or pricing
                                        strategy, marketing or development plans or strategies and any other
                                        information made
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        available to you via the Platform or Services which is or ought
                                        reasonably to be considered confidential in nature ("Confidential
                                        Information") without our prior written consent, except as permitted
                                        by clause 7.2.
                                    </p>
                                </li>
                                <li data-list-text="7.2">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        You may disclose Confidential Information as required by law or to
                                        your employees, officers, subcontractors, representatives or
                                        advisers who need to know such information for the purposes of
                                        carrying out your obligations under the Contract, provided that you
                                        shall ensure that such employees, officers, subcontractors,
                                        representatives and advisers comply with this clause 7.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="7.3">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You shall not use the Confidential Information for any purpose other
                                        than to perform your obligations under the Contract.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={8}>
                            <p
                                style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}
                            >
                                COMPETITION
                            </p>
                            <ol id="l17">
                                <li data-list-text="8.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        For the duration of the Contract , you shall not attempt to engage
                                        (whether directly or indirectly) any of the Businesses that you have
                                        had engagement with via the Platform, other than through us or the
                                        Platform without our prior written consent.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={9}>
                            <p
                                style={{
                                    paddingTop: "15pt",
                                    paddingLeft: "19pt",
                                    textIndent: "-13pt",
                                    textAlign: "left"
                                }}
                            >
                                INDEMNITY
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <ol id="l18">
                                <li data-list-text="9.1">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You agree to indemnify us and keep us indemnified against all and
                                        any expenses, losses, liabilities, damages, costs (including
                                        reasonable legal costs) incurred or suffered by us in relation to
                                        any claims or proceedings, which arise in any way from:
                                    </p>
                                    <ol id="l19">
                                        <li data-list-text="9.1.1">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "39pt",
                                                    textIndent: "-33pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                your use of the Platform and the Services;
                                            </p>
                                        </li>
                                        <li data-list-text="9.1.2">
                                            <p
                                                style={{
                                                    paddingTop: "15pt",
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                any breach by you of these Terms;
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="9.1.3">
                                            <p
                                                style={{
                                                    paddingLeft: "5pt",
                                                    textIndent: "0pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                any use of the Platform or the Services by anyone using your
                                                InfluencerAccount; or
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                            </p>
                                        </li>
                                        <li data-list-text="9.1.4">
                                            <p
                                                style={{
                                                    paddingLeft: "36pt",
                                                    textIndent: "-30pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                any claim made by the Businesses concerning your actions or
                                                services;
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li data-list-text="9.2">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "26pt",
                                            textIndent: "-20pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        This clause 10 shall survive termination of the Contract.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={10}>
                            <p
                                style={{ paddingLeft: "26pt", textIndent: "-20pt", textAlign: "left" }}
                            >
                                LIABILITY
                            </p>
                            <ol id="l20">
                                <li data-list-text="10.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Nothing in these Terms excludes or limits our liability for death or
                                        personal injury arising from our negligence, or our fraud or
                                        fraudulent misrepresentation, or any other liability that cannot be
                                        excluded or limited by English law.
                                    </p>
                                </li>
                                <li data-list-text="10.2">
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        To the extent permitted by law, we exclude all conditions,
                                        warranties, representations or other terms which may apply to this
                                        Contract or the Platform or the Services, whether express or implied
                                        and the Platform and the Services are provided on an "as is" basis.
                                    </p>
                                </li>
                                <li data-list-text="10.3">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        We shall not be liable whether in contract, tort (including
                                        negligence), breach of statutory duty, or otherwise for any loss of
                                        profits, reputation, business, goodwill, data, or for any special,
                                        indirect or consequential loss, costs or damages, whether it is
                                        foreseeable, known, foreseen or otherwise, under or in connection
                                        with these Terms. This exclusion of liability shall apply to all
                                        such losses whether they are direct, indirect or consequential
                                        losses.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="10.4">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        Subject to clauses 10.1 and 10.3, our total aggregate liability in
                                        contract, tort (including negligence), breach of statutory duty, or
                                        otherwise, under or in connection with the Platform, Services or
                                        this Contract shall be limited to £100.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={11}>
                            <p
                                style={{ paddingLeft: "26pt", textIndent: "-20pt", textAlign: "left" }}
                            >
                                CONTACT
                            </p>
                            <ol id="l21">
                                <li data-list-text="11.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        <a
                                            href="mailto:admin@promote.io"
                                            style={{
                                                color: "black",
                                                fontFamily: "Times New Roman serif",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                textDecoration: "none",
                                                fontSize: "13.5pt"
                                            }}
                                            target="_blank"
                                        >
                                            If you wish to contact us in writing, or if any clause in these
                                            Terms requires you to give us notice in writing (for example, to
                                            terminate the Contract), you can send this to us by email to{" "}
                                        </a>
                                        <a href="mailto:admin@promote.io" target="_blank">
                                            admin@promote.io
                                        </a>
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="11.2">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        If we have to contact you or give you notice in writing, we will do
                                        so by e- mail or by sending a notification to you on the Platform..
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={12}>
                            <p
                                style={{ paddingLeft: "26pt", textIndent: "-20pt", textAlign: "left" }}
                            >
                                OTHER IMPORTANT TERMS
                            </p>
                            <ol id="l22">
                                <li data-list-text="12.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        You must be 16 years of age to use the Platform. We reserve the
                                        right to terminate the Contract and deactivate your account in
                                        accordance with clause 8 if we discover you are under 16.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.2">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        We shall not be in breach of our responsibilities under these Terms
                                        nor liable for any delay in performing, or failure to perform, any
                                        of our responsibilities under these Terms if such delay or failure
                                        results from events, circumstances or causes beyond our reasonable
                                        control.
                                    </p>
                                </li>
                                <li data-list-text="12.3">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        We shall be entitled to change these Terms at any time in our sole
                                        discretion by providing an updated copy of these Terms on the
                                        Platform. For the avoidance of doubt, your use of the Platform
                                        following any update to these Terms shall be deemed as acceptance of
                                        those updates which (following such acceptance) shall apply to the
                                        Contract with effect from the day they were uploaded to the
                                        Platform. Any other variation of the Contract (other than as
                                        permitted under these Terms), shall only be effective if it is
                                        agreed in writing and signed by us.
                                    </p>
                                </li>
                                <li data-list-text="12.4">
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Nothing in these Terms are intended to, or shall be deemed to,
                                        establish any partnership or joint venture between you and us,
                                        constitute either you or us as the agent of the other, or authorise
                                        either you or us to make or enter into any commitments for or on
                                        behalf of the other.
                                    </p>
                                </li>
                                <li data-list-text="12.5">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        We may transfer our rights and obligations under these Terms to a
                                        third party. You are not entitled to assign or transfer your rights
                                        or obligations under these Terms unless we consent in writing.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.6">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        The Contract is between you and us. No other person shall have any
                                        rights to enforce any of its terms and for the avoidance of doubt,
                                        the Contracts (Rights of Third Parties) Act 1999 shall not apply to
                                        these Terms.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.7">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        The Contract constitutes the entire agreement between you and us in
                                        relation to your use of the Platform and the Services.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.8">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        Each of the clauses of these Terms operates separately. If any court
                                        or relevant authority decides that any of them are unlawful, the
                                        remaining clauses will remain in full force and effect.
                                    </p>
                                </li>
                                <li data-list-text="12.9">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        If we fail to insist that you perform any of your obligations under
                                        these the Contract or in law, or if we do not enforce our rights
                                        against you, or if we delay in doing so, that will not mean that we
                                        have waived our rights against you and will not mean that you do not
                                        have to comply with those obligations. If we do waive a default by
                                        you, we will only do so in writing, and that will not mean that we
                                        will automatically waive any later default by you.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.10">
                                    <p
                                        style={{
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "justify"
                                        }}
                                    >
                                        The Contract shall be governed and interpreted in accordance with
                                        English law. You and we both agree to submit to the exclusive
                                        jurisdiction of the English courts.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.11">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        We may enable you to link your User Account with a valid account on
                                        a third party social networking, email or content service such as
                                        Facebook, Instagram, YouTube, or Twitter, (such service, a
                                        "Third-Party Service" and each such account, a "Third-Party
                                        Account") by allowing PROMOTE to access your Third-Party Account, as
                                        is permitted under the applicable terms and conditions that govern
                                        your use of each Third-Party Account.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.12">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        You represent that you are entitled to disclose your Third-Party
                                        Account login information to PROMOTE and/or grant PROMOTE access to
                                        your Third- Party Account (including, but not limited to, for use
                                        for the purposes described herein) without breach by you of any of
                                        the terms and conditions that govern your use of the applicable
                                        Third-Party Account and without obligating PROMOTE to
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: "3pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        pay any fees or making PROMOTE subject to any usage limitations
                                        imposed by such third-party service providers.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.13">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        Force Majeure Event means any circumstance not within a party’s
                                        reasonable control including, without limitation: acts of God,
                                        flood, drought, earthquake or other natural disaster; epidemic or
                                        pandemic; terrorist attack, civil war, civil commotion or riots,
                                        war, threat of or preparation for war, armed conflict, imposition of
                                        sanctions, embargo, or breaking off of diplomatic relations;
                                        nuclear, chemical or biological contamination or sonic boom; any law
                                        or any action taken by a government or public authority, including
                                        without limitation imposing an export or import restriction, quota
                                        or prohibition, or failing to grant a necessary licence or consent;
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </li>
                                <li data-list-text="12.14">
                                    <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                    >
                                        If We are prevented, hindered or delayed in or from performing any
                                        of our obligations under this agreement by a Force Majeure Event, We
                                        shall not be in breach of this agreement or otherwise liable for any
                                        such failure or delay in the performance of such obligations. The
                                        time for performance of such obligations shall be extended
                                        accordingly.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={13}>
                            <p
                                style={{
                                    paddingTop: "15pt",
                                    paddingLeft: "26pt",
                                    textIndent: "-20pt",
                                    textAlign: "left"
                                }}
                            >
                                NON-CIRCUMVENTION &amp; GOOD FAITHNITY
                            </p>
                            <ol id="l23">
                                <li data-list-text="13.1">
                                    <p
                                        style={{
                                            paddingTop: "15pt",
                                            paddingLeft: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        You shall not in any manner, directly or indirectly attempt to
                                        circumvent the operation of this Agreement so as to otherwise
                                        deprive us of any of the benefits intended under or pursuant to this
                                        Agreement.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                        You hereby acknowledge, agree and declare that you owe REACH.IO LTD a duty
                        of utmost good faith in relation to your respective obligations under this
                        Agreement, the Platform and your relationship with the Businesses or any
                        contacts and clients that we may introduce to you through the Platform or
                        otherwise from time to time.
                    </p>

            </div>
        </>

    )
}
