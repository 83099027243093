import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import hero from '../../assets/images/case-study/hero.jpg';
import ImageCS11 from '../../assets/images/new-case-study/casestudy-1/image.jpg';
import ImageCS21 from '../../assets/images/new-case-study/casestudy-2/image.jpeg';
import ImageCS31 from '../../assets/images/new-case-study/casestudy-3/image.jpeg';
import ImageCS41 from '../../assets/images/new-case-study/casestudy-4/image.jpg';
import ImageCS51 from '../../assets/images/new-case-study/casestudy-5/image.jpg';
import ImageCS61 from '../../assets/images/new-case-study/casestudy-6/image.jpg';
import ImageCS71 from '../../assets/images/new-case-study/casestudy-7/image.webp';
import ImageCS81 from '../../assets/images/new-case-study/casestudy-8/image.jpg';
import ImageCS91 from '../../assets/images/new-case-study/casestudy-9/image.webp';
import ImageCS101 from '../../assets/images/new-case-study/casestudy-10/image.jpg';
import ImageCS111 from '../../assets/images/new-case-study/casestudy-11/image.jpeg';
import ImageCS121 from '../../assets/images/new-case-study/casestudy-12/image.jpg';
import ImageCS131 from '../../assets/images/new-case-study/casestudy-13/image.jpg';

import CaseStudy1logo from '../../assets/images/new-case-study/casestudy-1/logo-nobg.png';
import CaseStudy1video from '../../assets/images/new-case-study/casestudy-1/video.mp4';
import CaseStudy2logo from '../../assets/images/new-case-study/casestudy-2/logo.png';
import CaseStudy2video from '../../assets/images/new-case-study/casestudy-2/video.mp4';
import CaseStudy3logo from '../../assets/images/new-case-study/casestudy-3/logo.png';
import CaseStudy3video from '../../assets/images/new-case-study/casestudy-3/video.mp4';
import CaseStudy4logo from '../../assets/images/new-case-study/casestudy-4/logo.png';
import CaseStudy4video from '../../assets/images/new-case-study/casestudy-4/video.mp4';
import CaseStudy5logo from '../../assets/images/new-case-study/casestudy-5/logo.svg';
import CaseStudy5video from '../../assets/images/new-case-study/casestudy-5/video.mp4';
import CaseStudy6logo from '../../assets/images/new-case-study/casestudy-6/logo.png';
import CaseStudy6video from '../../assets/images/new-case-study/casestudy-6/video.mp4';
import CaseStudy7logo from '../../assets/images/new-case-study/casestudy-7/logo.webp';
import CaseStudy7video from '../../assets/images/new-case-study/casestudy-7/video.mp4';
import CaseStudy8logo from '../../assets/images/new-case-study/casestudy-8/logo.png';
import CaseStudy8video from '../../assets/images/new-case-study/casestudy-8/video.mp4';
import CaseStudy9logo from '../../assets/images/new-case-study/casestudy-9/logo.png';
import CaseStudy9video from '../../assets/images/new-case-study/casestudy-9/video.mp4';
import CaseStudy10logo from '../../assets/images/new-case-study/casestudy-10/logo.png';
import CaseStudy10video from '../../assets/images/new-case-study/casestudy-10/video.mp4';
import CaseStudy11logo from '../../assets/images/new-case-study/casestudy-11/logo.png';
import CaseStudy11video from '../../assets/images/new-case-study/casestudy-11/video.mp4';
import CaseStudy12logo from '../../assets/images/new-case-study/casestudy-12/logo.png';
import CaseStudy12video from '../../assets/images/new-case-study/casestudy-12/video.mp4';
import CaseStudy13logo from '../../assets/images/new-case-study/casestudy-13/logo.png';
import CaseStudy13video from '../../assets/images/new-case-study/casestudy-13/video.mp4';
import { Link } from 'react-router-dom';
import './casestudy.style.scss';


const CaseStudy = () => {
    document.title = "Case Studies | PROMOTE"

    return (
        <>
            <Header></Header>
            <div id='CaseStudy'>
                <section id='section1' className='section'>
                    <div className='container mx-auto h-full'>
                        <div className='flex flex-col h-full w-full'>
                            <div className="block bg-white rounded-lg">
                                <div className='grid sm:grid-cols-5'>
                                    <div className='col-span-2'>
                                        <h5 className="text-4xl tracking-tight font-extrabold text-left text-primary">Case Studies</h5>
                                        <p className='mt-5'>Explore our success stories where we connect brands with influential voices, driving impactful campaigns and measurable results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section2' className='section'>
                    <div className='container mx-auto'>
                        <div className='h-full w-full'>
                            <div className='grid sm:grid-cols-4 grid-cols-1 w-full sm:gap-10 gap-2'>
                                <Link to={`/case-study/byron-burgers`} className="sm:text-lg text-md w-full" state={{
                                    client: 'Byron Burgers',
                                    image: ImageCS11,
                                    logo: CaseStudy1logo,
                                    overview: 'Byron are a UK based burger brand with 9 stores across the country. The summer menu launch event for Byron’s venue in Covent Garden was the 1st menu launch organised by Promote for Byron Burgers. Byron requested influencers based in various parts of London to attend the event. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fashion and lifestyle as this appeals to their varied customer base. The high number of views meant that Byron were trending on social media and highly visible following the event.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '49',
                                    likes: '9,742',
                                    comments: '547',
                                    shares: '458',
                                    viewsLabel: ['Instagram', 'Tiktok'],
                                    views: [397538, 44171],
                                    viewsColor: ["#008e95", "#222831"],
                                    contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                    content: [28, 31, 2, 19],
                                    contentColor: ["#008e95", "#222831", "#A9A9A9", "#FFFFFF"],
                                    video: CaseStudy1video,
                                    horizontalVideo: 0,
                                    bgPosition: '-350px'
                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs1' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS11 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            Byron Burgers <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/case-study/marco-pierre-white`} className="sm:text-lg text-md" state={{
                                    client: 'Marco Pierre White',
                                    image: ImageCS21,
                                    logo: CaseStudy2logo,
                                    overview: 'Marco Pierre White are a UK based brand with 18 steakhouse bar & grill locations across the country. The summer menu launch event for MPW’s flagship Birmingham venue located within The Cube Hotel was the 1st menu launch organised by Promote for Marco Pierre White Restaurants. MPW requested influencers based in various parts of Birmingham to attend the event. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fashion and lifestyle as this appeals to their varied customer base. The high number of views meant that Marco Pierre White Restaurants were trending on social media and highly visible following the event.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '21',
                                    likes: '6,219',
                                    comments: '321',
                                    shares: '441',
                                    viewsLabel: ['Reel', 'Tiktok', 'Static Grid Post'],
                                    views: [164393, 19340, 9670],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                    content: [12, 10, 2, 13],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy2video,
                                    horizontalVideo: 0,
                                    bgPosition: 'center'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs2' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS21 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">

                                                            Marco Pierre White <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/case-study/t4-elephant-and-castle`} className="sm:text-lg text-md" state={{
                                    client: 'T4 Elephant and Castle',
                                    image: ImageCS31,
                                    logo: CaseStudy3logo,
                                    overview: 'T4 is a global bubble tea company that has over 60 stores in the UK. Promote has organised 6 influencer events for T4’s new store openings. The influencer content focused on T4\’s extensive menu, bubble tea creation, their reactions and the location of the new store openings. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views at each event meant that each T4 store was trending on social media and highly visible following the events.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '28',
                                    likes: '5,496',
                                    comments: '257',
                                    shares: '25',
                                    viewsLabel: ['Instagram', 'Tiktok'],
                                    views: [73158, 48772],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                    content: [23, 28, 1, 2],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy3video,
                                    horizontalVideo: 1,
                                    bgPosition: 'center'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs4' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS31 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            T4 Elephant and Castle <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/case-study/mary-brown-chicken`} className="sm:text-lg text-md" state={{
                                    client: 'Mary Brown Chicken',
                                    image: ImageCS41,
                                    logo: CaseStudy4logo,
                                    overview: 'MB Chicken are a premium fried chicken brand based in Canada and the US with over 300 stores globally. The launch event for MB’s venue in Lisburn was the 1st store launch organised by Promote for MB Chicken and their first store in the UK. MB Chicken requested food and beverage influencers local to Lisburn and Belfast to attend the event. They also requested influencers who had a particular passion for MB Chicken and any links to Canada. The high number of views meant that MB Chicken were trending on social media and highly visible',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '37',
                                    likes: '37,132',
                                    comments: '3,668',
                                    shares: '6,022',
                                    viewsLabel: ['Instagram', 'Tiktok'],
                                    views: [835152, 208788],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                    content: [22, 37, 16, 2],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy4video,
                                    horizontalVideo: 1,
                                    bgPosition: '-150px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs3' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS41 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            MB Chicken <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/oakberry-acai`} className="sm:text-lg text-md" state={{
                                    client: 'Oakberry Acai',
                                    image: ImageCS51,
                                    logo: CaseStudy5logo,
                                    overview: 'Oakberry are a UK based Açai brand with over 700 stores across the world. The Rathbone Place launch event for Oakberry’s flagship venue was the first event organised for Oakberry. Oakberry requested influencers based in various parts of London to attend the event. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views meant that Oakberry were trending on social media and highly visible following the event.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '24',
                                    likes: '1752',
                                    comments: '989',
                                    shares: '',
                                    viewsLabel: ['Story', 'Reel / Tiktok', 'Static Grid Post'],
                                    views: [141659, 16667, 8333],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                    content: [22, 37, 16, 2],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy5video,
                                    horizontalVideo: 0,
                                    bgPosition: '-150px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs3' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS51 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            OakBerry Acai <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/revolution-bar`} className="sm:text-lg text-md" state={{
                                    client: 'Revolution Bar',
                                    image: ImageCS61,
                                    logo: CaseStudy6logo,
                                    overview: 'Revolution are a UK based bar and nightclub brand with 65 venues across the country. The Bangers Bingo promotion event for Byron`s venue in Cambridge was  organised by Promote for Revolution to attract more students to their events. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fashion and lifestyle as this appeals to their varied customer base. The high number of views meant that Revolution Cambridge were trending on social media and highly visible following the event.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '6',
                                    likes: '1801',
                                    comments: '17',
                                    shares: '36',
                                    viewsLabel: ['Reel', 'Tiktok'],
                                    views: [36080, 4009],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories", "Tiktok"],
                                    content: [1, 3, 4],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy6video,
                                    horizontalVideo: 0,
                                    bgPosition: '-150px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs3' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS61 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            Revolution Bar <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/kill-the-cat-bar`} className="sm:text-lg text-md" state={{
                                    client: 'Kill The Cat Bar',
                                    image: ImageCS71,
                                    logo: CaseStudy7logo,
                                    overview: 'Kill The Cat is a UK based specialist craft beer company that has two venues based in Spitalfields, London. Promote organised an influencer event to celebrate their new creative dishes and paired beer tasting menu experience. The influencer content focused on the range of beers and dishes, craft beer pouring and tasting, their reactions and the location of the stores. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fashion and lifestyle as this appeals to their varied customer base. The high number of views at the event meant that Kill The Cat was trending on social media and highly visible following the events.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '6',
                                    likes: '384',
                                    comments: '81',
                                    shares: '',
                                    viewsLabel: ['Instagram'],
                                    views: [33877],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories"],
                                    content: [5, 2],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy7video,
                                    horizontalVideo: 0,
                                    bgPosition: '-200px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs3' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS71 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            Kill The Cat Bar <i class="fa-solid fa-arrow-right"></i>

                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/wendys`} className="sm:text-lg text-md" state={{
                                    client: 'Wendy’s',
                                    image: ImageCS81,
                                    logo: CaseStudy8logo,
                                    overview: 'Wendy’s are a US based fast food chain brand with over 7,240 stores across the world. Promote organised recurring influencer visits to 4 of the UK based stores in Portsmouth, Southend, Chelmsford and Guildford. Wendy’s requested local influencers to attend the venues. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views meant that Wendy’s were trending on social media and highly visible following the visits.',
                                    service: 'Recurring Influencer',
                                    location: 'United Kingdom',
                                    creators: '10',
                                    likes: '1,060',
                                    comments: '122',
                                    shares: '81',
                                    viewsLabel: ['Instagram', 'Tiktok'],
                                    views: [47940, 11985],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Tiktok"],
                                    content: [2, 4],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy8video,
                                    horizontalVideo: 0,
                                    bgPosition: '-200px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs8' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS81 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Recurring Influencer</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            Wendy's <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/youmesushi`} className="sm:text-lg text-md" state={{
                                    client: 'YouMeSushi',
                                    image: ImageCS91,
                                    logo: CaseStudy9logo,
                                    overview: 'YouMeSushi are a UK based Sushi chain brand with over 30 stores across the UK. Promote organised recurring influencer visits to 24 of the UK based stores. YouMeSushi requested local influencers to attend the venues who produce authentic and unique content. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views meant that YouMeSushi were trending on social media and highly visible following the visits.',
                                    service: 'Recurring Influencer',
                                    location: 'United Kingdom',
                                    creators: '16',
                                    likes: '163,181',
                                    comments: '140',
                                    shares: '143',
                                    viewsLabel: ['Instagram', 'Tiktok'],
                                    views: [130545, 32636],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories"],
                                    content: [2, 5],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy9video,
                                    horizontalVideo: 0,
                                    bgPosition: '-200px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs9' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS91 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Recurring Influencer</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            YouMeSushi <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/i-am-döner`} className="sm:text-lg text-md" state={{
                                    client: 'I am Döner',
                                    image: ImageCS101,
                                    logo: CaseStudy10logo,
                                    overview: 'I am Döner are a UK based contemporary Döner brand with 6 stores across the UK. Promote organised recurring influencer visits all of their UK based stores. I am Döner requested local student influencers to attend the venues who produce authentic and unique content. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views meant that I am Döner were trending on social media and highly visible following the visits.',
                                    service: 'Recurring Influencer',
                                    location: 'United Kingdom',
                                    creators: '15',
                                    likes: '3,175',
                                    comments: '78',
                                    shares: '970',
                                    viewsLabel: ['Instagram', 'Tiktok'],
                                    views: [51857.19, 53974],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories"],
                                    content: [2, 5],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy10video,
                                    horizontalVideo: 0,
                                    bgPosition: '-200px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs9' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS101 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Recurring Influencer</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            I am Doner <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/pitmaster`} className="sm:text-lg text-md" state={{
                                    client: 'Pitmaster',
                                    image: ImageCS111,
                                    logo: CaseStudy11logo,
                                    overview: 'Pitmaster are a UK based BBQ and smokehouse with 2 stores across the UK. Promote organised an influencer event for their Birmingham store opening. The influencer content focused on the long and skilled cooking process, halal meat, their reaction and the location of the new venue. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views meant that Pitmaster were trending on social media and the Birmingham store was highly visible following the event.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '26',
                                    likes: '17,507',
                                    comments: '432',
                                    shares: '6,558',
                                    viewsLabel: ['Tiktok', 'Instagram'],
                                    views: [361031, 90258],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "Insta Stories", "Grid Post", "TikTok"],
                                    content: [7, 26, 2, 23],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy11video,
                                    horizontalVideo: 0,
                                    bgPosition: '-325px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs9' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS111 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            Pitmaster <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/inamo`} className="sm:text-lg text-md" state={{
                                    client: 'Inamo',
                                    image: ImageCS121,
                                    logo: CaseStudy12logo,
                                    overview: 'Inamo are a UK based Sushi chain brand with 3 stores in the UK. Promote organised recurring influencer visits to 2 of the London based stores. Inamo requested local influencers from different areas in London to attend the venues who produce authentic and unique content. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views meant that Inamo were trending on social media and highly visible following the visits.',
                                    service: 'Recurring Influencer',
                                    location: 'United Kingdom',
                                    creators: '15',
                                    likes: '6,106',
                                    comments: '112',
                                    shares: '2,744',
                                    viewsLabel: ['Tiktok', 'Instagram'],
                                    views: [101115.7, 43335.3],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "TikTok"],
                                    content: [8, 9],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy12video,
                                    horizontalVideo: 0,
                                    bgPosition: '0px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs9' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS121 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Recurring Influencer</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            Inamo <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={`/case-study/cupp`} className="sm:text-lg text-md" state={{
                                    client: 'Cupp',
                                    image: ImageCS131,
                                    logo: CaseStudy13logo,
                                    overview: 'CUPP is a bubble tea chain within the UK with around 35 stores. Promote has organised serval influencer events over the years for new store openings and new menu launches. Promote also organised recurring influencers to 22 of their stores. The influencer content focused on the stores’ aesthetics, bubble tea creation, their reactions and the location. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views at each event and the recurring visits meant that CUPP’s stores were trending on social media and highly visible.',
                                    service: 'Influencer Events',
                                    location: 'United Kingdom',
                                    creators: '21',
                                    likes: '3,809',
                                    comments: '213',
                                    shares: '',
                                    viewsLabel: ['Tiktok', 'Instagram'],
                                    views: [101115.7, 43335.3],
                                    viewsColor: ["#008e95", "#222831", "#A4243B"],
                                    contentLabel: ["Reel", "TikTok"],
                                    content: [8, 9],
                                    contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                    video: CaseStudy13video,
                                    horizontalVideo: 1,
                                    bgPosition: '-350px'

                                }}>
                                    <div className='flex p-2'>
                                        <div className="bg-white rounded-lg border border-gray-200 w-full case-card">
                                            <button type="button" className='w-full'>
                                                <div className='casestudy-img cs9' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(" + ImageCS131 + ")" }}></div>
                                                <div className="p-5 text-left">
                                                    <div className=''>
                                                        <span className='rounded-full text-xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                        <h5 className="text-black text-left text-md mt-2 whitespace-nowrap">
                                                            Cupp <i class="fa-solid fa-arrow-right"></i>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
            <Footer></Footer>
        </>
    );
}

export default CaseStudy;