import { useEffect, useState, useRef } from 'react';
import useAxios, { ApiUrl, imgApi } from '../../API/Api.config';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Header from '../../components/DashboardHeader/DashboardHeader';
import Sidebar from '../../components/Sidebar/Sidebar';
import ImageChatApi from '../../API/ImageChatApi';
import TokenService from '../../API/token.service';
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';
import Modal from 'react-modal';
import Popup from 'reactjs-popup';
import { useForm } from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'reactjs-popup/dist/index.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import './search.style.scss';
import Select from 'react-select'
import { Audio } from 'react-loader-spinner'
import Dropdown from '../../components/DropDownList/Dropdown'
import Range from "../../components/Range/Range";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import chroma from "chroma-js";
import { Tooltip, Button } from 'flowbite-react';

Modal.setAppElement('#root');

const Chat = () => {

    document.title = "Search | Promote"

    const token = TokenService.getLocalAccessToken()
    const nav = useNavigate()
    const [recevier, setRecevier] = useState(0);
    // const [message, setMessage] = useState('Hi, it would be great to collaborate');
    const [socketUrl, setSocketUrl] = useState('');
    const [messageHistory, setMessageHistory] = useState([]);
    const [allData, setAllData] = useState([]);
    const [friendData, setFriendData] = useState([]);
    const [image, setImage] = useState('');
    const [connectionNumber, setConnectionNumber] = useState(0);
    const [currentConnection, setCurrentConnection] = useState(0);
    const [limit, setLimit] = useState(true);
    const [refresh, setRefresh] = useState(0);
    const [age_to, setAge_to] = useState('')
    const [age_from, setAge_from] = useState('')
    const [followers_from, setFollowers_from] = useState('')
    const [eduPlace, setEduPlace] = useState('')
    const [followers_to, setFollowers_to] = useState('')
    const [username, setUsername] = useState('')
    const [gender, setGender] = useState('')
    const [price_per_post_from, setPrice_per_post_from] = useState(0);
    const [price_per_post_to, setPrice_per_post_to] = useState(999999);
    const [payment_type, setPayment_type] = useState('');
    const [facebook, setFacebook] = useState('')
    const [tiktok, setTiktok] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [youtube, setYoutube] = useState('')
    const [instagram, setInsagram] = useState('')
    const [location, setLocation] = useState('');
    const [interest, setInterest] = useState('');
    const [bio, setBio] = useState('');
    const [interestOption, setinterestOption] = useState([]);
    const paymentStatus = secureLocalStorage.getItem('payment_status');
    const profileCompleted = secureLocalStorage.getItem('profile_completed');
    const role = secureLocalStorage.getItem('role')
    const userid = secureLocalStorage.getItem("user_id")
    const searchParam = useLocation()
    const [page, setPage] = useState(20)
    const [offset, setOffset] = useState(0)
    const start_page = 0
    const didUnmount = useRef(false);
    const [chatStatus, setChatStatus] = useState("");
    const MySwal = withReactContent(Swal)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonText, setButtonText] = useState('Show More');

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    // const { lastMessage, readyState, sendJsonMessage, } = useWebSocket(socketUrl,
    //     {
    //         shouldReconnect: (closeEvent) => {
    //             /*
    //             useWebSocket will handle unmounting for you, but this is an example of a 
    //             case in which you would not want it to automatically reconnect
    //           */
    //             return didUnmount.current === false;
    //         },
    //         reconnectAttempts: 10,
    //         reconnectInterval: 3000,
    //     }
    // );

    // useEffect(() => {
    //     if (lastMessage !== null) {
    //         setMessageHistory((prev) => prev.concat(lastMessage));
    //     }
    // }, [lastMessage, setMessageHistory]);

    // const handleClickChangeSocketUrl = (recevier) => {
    //     setSocketUrl(`socketApiUrl/ws/chat/api/${recevier}/?token=${token}`);
    //     console.log(recevier)
    // };

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    function handleClick() {
        setOffset(offset + 20);
        setButtonText('Loading..');
    }

    const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": `Token ${token}`,
    }

    // async function influencerInterest() {
    //     try {
    //         await axios.get(ApiUrl + 'influencer_interest/', { headers: { 'Accept': 'application/json', 'Authorization': 'Token ' + token } })
    //             .then(response => setInterest(response.data))
    //             .catch(error => {
    //                 if (error.request.status === 401) {
    //                     nav('/login/')
    //                 }
    //             });
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    const resetSearch = (event) => {
        searchParam.state = null
    }

    async function getInfluencerFrind() {
        // setIsLoading(loading)
        try {
            const response = await axios.get(ApiUrl + 'influencer_friend/', { headers })
                .finally(() => { setIsLoading(false); setButtonText('Show More') }
                );

            if (response.data !== null) {
                setFriendData(response.data)
                if (searchParam.state !== null) {
                    document.getElementById('connectBtn').click()

                }
            } else if (response.status === 401) {
                nav('/login/')
            }

        }
        catch (error) {
            console.log(error);
        }
    }

    async function getInfluencers() {
        try {
            const response = await axios.get(ApiUrl + `search/?id=${searchParam.state === null ? '' : searchParam.state.id}&facebook=${facebook}&instagram=${instagram}&tiktok=${tiktok}&youtube=${youtube}&linkedin=${linkedin}&gender=${gender}&age__range=${[age_from ? age_from : 0, age_to ? age_to : 999]}&followers_number__range=${[followers_from ? followers_from : 0, followers_to ? followers_to : 99999999999]}&price_per_post__range=${[price_per_post_from ? price_per_post_from : 0, price_per_post_to === 500 ? 9999999 : price_per_post_to]}&user__username=${username}&payment_type=${payment_type}&university=${eduPlace}&interest__interest=${decodeURIComponent(interestOption)}&location=${location}&page=${start_page}&limit=${page}&offset=${offset}&bio=${bio}`, { headers })
                .finally(() => { setIsLoading(false); setButtonText('Show More') }
                );

            if (response.data !== null) {
                setAllData(response.data.results);
                // setFriendData(response.data.friend);
                if (searchParam.state !== null) {
                    document.getElementById('connectBtn').click()

                }
            } else if (response.status === 401) {
                nav('/login/')
            }

        }
        catch (error) {
            console.log(error);
        }
    }

    const onSubmit = () => {
        getInfluencers()
        setInterests()
    }

    useEffect(() => {
        setIsLoading(true)
        getInfluencers()
        // influencerInterest()
        getInfluencerFrind()
    }, [])

    useEffect(() => {
    }, [age_from, age_to, username, payment_type, interest, location, interestOption, price_per_post_from, price_per_post_to, followers_from, followers_to, bio])

    async function handleFavoritConnection(data) {
        try {
            await axios.post(ApiUrl + `favorite_content/${data.friend}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + token
                }
            })
                .then(response => console.log(response))
                .catch(error => console.log(error))
        } catch (e) {
            console.log(e)
        }
    }

    async function numberFavoritConnection() {
        try {
            await axios.get(ApiUrl + 'favorite_content_number/', {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }
            })
                .then(response => {
                    setCurrentConnection(response.data.connection_number)
                    if (response.status === 401) {
                        nav('/login/')
                    }
                })
                .catch(error => {
                    if (error.request.status === 401) {
                        nav('/login/')
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    async function sendMessage(data, name) {
        const token = TokenService.getLocalAccessToken();

        try {
            await axios.post(ApiUrl + `send_messages/`, data,
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Token ${token}`
                    }
                })
                .then(function (response) {
                    MySwal.fire({
                        position: 'top',
                        toast: true,
                        icon: 'success',
                        title: 'Your message has been sent and you will be redirected to messages page shortly',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    setTimeout(function () {
                        nav('/messages?name=' + name.replace(' ', '_'));
                    }, 3000);
                })
                .catch(function (error) {
                    if (error.request.status === 400) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'There was an error sending the messages',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    };


    const handleClickSendMessage = (id, message, name) => {
        sendMessage({ "sender": userid, "message": message, 'receiver': id }, name)
        handleFavoritConnection({ 'friend': id });
        setRefresh(refresh + 1);
    };

    const handleConnectButton = async (id, name) => {

        const connectTextDefault = `Hi ${name}, it would be great to collaborate`

        Swal.fire({
            title: 'Do you want to connect with ' + name,
            text: 'Please be advised that connecting with this influencer may result in a decrease in your overall number of connections.',
            input: 'text',
            inputValue: connectTextDefault,
            inputPlaceholder: `Your message to ${name}`,
            inputLabel: `Your message to ${name}`,
            showCloseButton: true,
            cancelButtonText: 'Loading',
            confirmButtonText: 'Connect',
            focusConfirm: false,
            confirmButtonColor: '#008e95',
            customClass: {
                title: 'title-text',
                input: 'pop-input'
            },

        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                handleClickSendMessage(id, result.value, name)

            }
        })
    }

    useEffect(() => { }, [recevier])

    async function getConnectionsNumber() {
        try {
            await axios.get(ApiUrl + 'payment_register/', {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Token ' + token,
                }
            }).then(response => {
                console.log(response.data)
                if (response.data[0]?.connection_number) {
                    setConnectionNumber(response.data[0]?.connection_number)
                    console.log(response.data[0]?.connection_number)
                }
            }).catch(error => console.log(error));
        } catch (e) { console.log(e) }
    }

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyState];

    // const { response, isLoading, status } = useAxios({
    //     method: "get",
    //     url: 'all_influencer/',
    //     headers: {
    //         "Authorization": `Token ${token}`
    //     }
    // });

    // useEffect(() => {
    //     if (status === 401) {
    //         nav('/login/')
    //     }
    // }, [])

    useEffect(() => {
        numberFavoritConnection()
        console.log(currentConnection >= connectionNumber)

        if (currentConnection >= connectionNumber) {
            setLimit(false);
        } else if (connectionNumber === 0) {
            setLimit(false)
        } else {
            setLimit(true)
        }

    }, [refresh, currentConnection, connectionNumber])

    useEffect(() => {
    }, [refresh])

    useEffect(() => {
    }, [limit])

    useEffect(() => {

        if (paymentStatus) {
            getConnectionsNumber()
            numberFavoritConnection()
        } else {
            setConnectionNumber(0)
        }
    }, [connectionNumber, currentConnection])

    const overlayStyle = { width: '50%' }
    const contentStyle = window.innerWidth > 768 ? { width: '30%' } : { width: '85%' };
    const position = window.innerWidth > 768 ? 'left center' : 'center';

    const handleUserDetails = (id, connect) => {
        secureLocalStorage.setItem('userid', id);
        secureLocalStorage.setItem('influencer_connect', connect);
        window.open('/show-user/', '_blank');
    }


    const handleConnectHide = (e) => {

        document.getElementById('connectedInfluencers').style.display = 'none';
        document.getElementById('hideBtn').style.display = 'none';
        document.getElementById('showBtn').style.display = 'block';

    }

    const handleConnectShow = (e) => {

        document.getElementById('connectedInfluencers').style.display = 'block';
        document.getElementById('hideBtn').style.display = 'block';
        document.getElementById('showBtn').style.display = 'none';

    }


    const setInterests = async (event) => {
        event.map((obj) => (
            setinterestOption([
                ...interestOption,
                obj.value.replace(/\s+/g, ' ')
            ])
        ))
    }

    const extractUsernameFromUrl = (url) => {
        const regex = {
            instagram: /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)/,
            facebook: /(?:http(?:s)?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9\.]+)/,
            tiktok: /(?:http(?:s)?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9_\.]+)/,
            linkedin: /(?:http(?:s)?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9\-\.]+)/,
            youtube: /(?:http(?:s)?:\/\/)?(?:www\.)?youtube\.com\/(?:channel\/)?([a-zA-Z0-9_\-]+)/
        };

        let username = null;
        Object.keys(regex).some((platform) => {
            const match = url.match(regex[platform]);
            if (match) {
                username = match[1];
                return true;
            }
        });

        return username;
    };

    const createSocialMediaUrl = (platform, username) => {
        switch (platform) {
            case 'instagram':
                return `https://www.instagram.com/${username}`;
            case 'facebook':
                return `https://www.facebook.com/${username}`;
            case 'tiktok':
                return `https://www.tiktok.com/@${username}`;
            case 'linkedin':
                return `https://www.linkedin.com/in/${username}`;
            case 'youtube':
                return `https://www.youtube.com/channel/${username}`;
            default:
                return null;
        }
    };

    function handleChangeSoical(e) {
        let isChecked = e.target.checked;
        let name = e.target.name
        if (name === 'facebook' & isChecked === true) {
            setFacebook(true)
        } else if (name === 'facebook' & isChecked === false) {
            setFacebook('')
        }
        if (name === 'linkedin' & isChecked === true) {
            setLinkedin(true)
        } else if (name === 'linkedin' & isChecked === false) {
            setLinkedin('')
        }
        if (name === 'instagram' & isChecked === true) {
            setInsagram(true)
        } else if (name === 'instagram' & isChecked === false) {
            setInsagram('')
        }
        if (name === 'tiktok' & isChecked === true) {
            setTiktok(true)
        } else if (name === 'tiktok' & isChecked === false) {
            setTiktok('')
        }
        if (name === 'youtube' & isChecked === true) {
            setYoutube(true)
        } else if (name === 'youtube' & isChecked === false) {
            setYoutube('')
        }
    }

    useEffect(() => {
        getInfluencers(false)
    }, [page])

    useEffect(() => {
        if (!profileCompleted) {
            if (role == 1) {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/influencer/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            } else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Profile Not Complete',
                    text: 'Please complete the onboarding process before using the application',
                    showConfirmButton: false,
                    footer: '<a href="/business/onboarding" class="bg-secondary text-white p-4 rounded-lg">Start the onboarding process</a>'
                })
            }
        }
    }, [])

    return (
        <>
            <Header />
            <Sidebar />
            <div className='ml-0 sm:ml-64 mt-20' id='search'>
                <div className='container mx-auto '>
                    <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                        <div className='sm:basis-1/2 basis-full'>
                            <h2 className="p-4 pb-0 text-2xl font-bold">Search</h2>
                        </div>
                        <div className='sm:basis-1/2 basis-full'>
                            {role == 2 &&
                                <div className="flex flex-row sm:justify-end justify-start items-center">
                                    <div className="p-4">
                                        Total Connection Number: {currentConnection}/{connectionNumber}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {role === 2 && <div className='bg-fourth p-4 rounded-lg shadow-lg mb-4'>
                        <div className='flex flex-row flex-wrap sm:flex-nowrap'>
                            <div className='sm:basis-11/12 basis-full'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Your Email</label> */}
                                    <div className="relative">
                                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                        </div>
                                        <input type="text" id="search" value={username} onChange={(e) => { setUsername(e.target.value) }} className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Search" />
                                        <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-4 py-2">Search</button>
                                        {searchParam.state === null ? '' : <button type="submit" className="text-white absolute right-24 bottom-2.5 bg-secondary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-4 py-2" onClick={(e) => { resetSearch(e) }}><i className="fa-solid fa-xmark"></i></button>}
                                    </div>
                                </form>
                                {/* <form >
                                    <input type='text' name='charitable' value={username} onChange={(e) => setUsername(e.target.value)} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    <button>Search</button>
                                </form> */}
                            </div>
                            <div className='sm:basis-1/12 basis-full flex flex-row sm:justify-end justify-start sm:mt-0 mt-4 items-center'>
                                <div className="w-full sm:px-4 px-0">
                                    <div className="w-full">
                                        {paymentStatus === true && <button id="dropdownLeftButton" data-dropdown-toggle="dropdownLeft" data-dropdown-placement="left" className=" w-full mb-3 md:mb-0 text-white bg-primary font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex justify-center items-center" type="button">Filters</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div id="dropdownLeft" className="bg-fourth p-4 rounded shadow">
                        {/* <div className='bg-secondary p-4 text-white rounded-lg'>
                            <p className='text-md'>Filters</p>
                        </div> */}
                        <form className="sm:mt-4 mt-0" onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid sm:grid-cols-3 grid-cols-1 gap-4">
                                <div className="p-2 flex flex-col">
                                    <label htmlFor="email" className="block text-sm text-gray-800">Gender</label>
                                    <select className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" id="select" onChange={(e) => setGender(e.target.value)} value={"Select payment"}>
                                        <option value="" selected >Not Specific</option>
                                        <option value={"male"}>Male</option>
                                        <option value={"female"}>Female</option>
                                        <option value={"other"}>Other</option>
                                    </select>
                                    {/* <input type='text' name='gender' onChange={(e) => setGender(e.target.value)} value={gender} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40" /> */}
                                </div>
                                <div className="p-2 flex flex-col ">
                                    <label htmlFor="bio" className="block text-sm text-gray-800">Keyword Search</label>
                                    <input type='text' name='bio' value={bio} onChange={(e) => setBio(e.target.value)} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                                </div>
                                <div className="p-2 flex flex-col">
                                    <label htmlFor="email" className="block text-sm text-gray-800">Cost per post</label>
                                    <Range
                                        min={0}
                                        max={500}
                                        onChange={({ min, max }) => (setPrice_per_post_from(min), setPrice_per_post_to(max))}
                                    />
                                </div>

                                <div className="p-2 flex flex-col ">
                                    <label htmlFor="email" className="block text-sm text-gray-800 mb-2">Location</label>
                                    <GooglePlacesAutocomplete
                                        apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                        selectProps={{
                                            onChange: (value) => { setLocation(value.value.description) },
                                            styles: {
                                                input: (provided) => ({
                                                    ...provided,
                                                    color: 'black',
                                                    boxShadow: "none",
                                                    outline: "none",
                                                    ':hover': {
                                                        '--tw-ring-color': 'black !important'
                                                    },
                                                    ':focus': {
                                                        '--tw-ring-color': 'black !important'
                                                    },
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: '1px solid black',
                                                    ':hover': {
                                                        border: '1px solid #008e95 !important'
                                                    },
                                                    boxShadow: "none",
                                                    outline: "none"
                                                }),

                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                    const color = chroma('#008e95');
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? undefined
                                                            : isSelected
                                                                ? data.color
                                                                : isFocused
                                                                    ? color.alpha(0.1).css()
                                                                    : undefined,
                                                        ':active': {
                                                            ...styles[':active'],
                                                            backgroundColor: !isDisabled
                                                                ? isSelected
                                                                    ? color
                                                                    : color.alpha(0.3).css()
                                                                : undefined,
                                                        },
                                                    };
                                                },
                                            }
                                        }}
                                    />
                                </div>
                                <div className="p-2 flex flex-col ">
                                    <label htmlFor="email" className="block text-sm text-gray-800">Payment Type</label>
                                    <select className="block w-full px-4 py-2 mt-2 bg-white border rounded-lg focus:border-primary focus:ring-primary" id="select" onChange={(e) => setPayment_type(e.target.value)} value={"Select payment"}>
                                        <option value="" selected >Not Specific</option>
                                        <option value={"any"}>Any</option>
                                        <option value={"cash"}>Cash</option>
                                        <option value={"product"}>Product</option>

                                    </select>
                                    {/* <input type='text' name='age_to' value={payment_type} onChange={(e) => setPayment_type(e.target.value)} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40" /> */}
                                </div>
                                <div className="p-2 flex flex-col">
                                    <label htmlFor="email" className="block text-sm text-gray-800">Age</label>
                                    <Range
                                        min={18}
                                        max={80}
                                        onChange={({ min, max }) => (setAge_from(min), setAge_to(max))}
                                    />
                                </div>
                                <div className="p-2 flex flex-col ">
                                    <label htmlFor="interest" className="block text-sm text-gray-800 mb-2">Interests (3 max)</label>
                                    <Select
                                        isMulti
                                        id="select"
                                        options={interest}
                                        onChange={(e) => setinterestOption(e.map(val => val.value.replace(/\s+/g, ' ')))}
                                        styles={{
                                            input: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                                boxShadow: "none",
                                                outline: "none",
                                                ':hover': {
                                                    '--tw-ring-color': 'black !important'
                                                },
                                                ':focus': {
                                                    '--tw-ring-color': 'black !important'
                                                },
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                border: '1px solid black',
                                                ':hover': {
                                                    border: '1px solid #008e95 !important'
                                                },
                                                boxShadow: "none",
                                                outline: "none"
                                            }),

                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                const color = chroma('#008e95');
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? undefined
                                                        : isSelected
                                                            ? data.color
                                                            : isFocused
                                                                ? color.alpha(0.1).css()
                                                                : undefined,
                                                    ':active': {
                                                        ...styles[':active'],
                                                        backgroundColor: !isDisabled
                                                            ? isSelected
                                                                ? color
                                                                : color.alpha(0.3).css()
                                                            : undefined,
                                                    },
                                                };
                                            },
                                        }}
                                    />
                                </div>
                                <div className="p-2 flex flex-col ">
                                    <label htmlFor="email" className="block text-sm text-gray-800 mb-2 ">University or Place of Education</label>
                                    <GooglePlacesAutocomplete
                                        apiKey={'AIzaSyBr12jhy0XdpOtnvR4YJRmCIzWrdL3DYNw'}
                                        selectProps={{
                                            onChange: (value) => { setEduPlace(value.value.description) },
                                            styles: {
                                                input: (provided) => ({
                                                    ...provided,
                                                    color: 'black',
                                                    boxShadow: "none",
                                                    outline: "none",
                                                    ':hover': {
                                                        '--tw-ring-color': 'black !important'
                                                    },
                                                    ':focus': {
                                                        '--tw-ring-color': 'black !important'
                                                    },
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: '1px solid black',
                                                    ':hover': {
                                                        border: '1px solid #008e95 !important'
                                                    },
                                                    boxShadow: "none",
                                                    outline: "none"
                                                }),

                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                    const color = chroma('#008e95');
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? undefined
                                                            : isSelected
                                                                ? data.color
                                                                : isFocused
                                                                    ? color.alpha(0.1).css()
                                                                    : undefined,
                                                        ':active': {
                                                            ...styles[':active'],
                                                            backgroundColor: !isDisabled
                                                                ? isSelected
                                                                    ? color
                                                                    : color.alpha(0.3).css()
                                                                : undefined,
                                                        },
                                                    };
                                                },
                                            }
                                        }}
                                    />
                                    {/* <input type='text' name='edu_place' value={eduPlace} onChange={(e) => setEduPlace(e.target.value)} className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border rounded-lg focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40" /> */}
                                </div>
                                <div className="p-2 flex flex-col">
                                    <label htmlFor="email" className="block text-sm text-gray-800">Followers</label>
                                    <Range
                                        min={0}
                                        max={10000}
                                        onChange={({ min, max }) => (setFollowers_from(min), setFollowers_to(max))}
                                    />
                                </div>
                            </div>
                            <div className="p-2 flex flex-col mt-3">
                                <label htmlFor="email" className="block text-sm text-gray-800 mb-2">Social Media</label>
                                <div className='flex flex-row flex-wrap'>
                                    <div className='sm:basis-1/5 basis-1/2 border p-2 bg-white rounded-lg'>
                                        <input type={"checkbox"} id="facebook" name="facebook" className="text-primary" onClick={(e) => handleChangeSoical(e)} />
                                        <label htmlFor="facebook" className="inline-block ml-2 text-sm text-gray-800"><i className='fa-brands fa-facebook'></i> Facebook</label>
                                    </div>
                                    <div className='sm:basis-1/5 basis-1/2 border p-2 bg-white rounded-lg'>
                                        <input type={"checkbox"} id="instagram" name="instagram" className="text-primary" onClick={(e) => handleChangeSoical(e)} />
                                        <label htmlFor="instagram" className="inline-block ml-2 text-sm text-gray-800"><i className='fa-brands fa-instagram'></i> Instagram</label>
                                    </div>
                                    <div className='sm:basis-1/5 basis-1/2 border p-2 bg-white rounded-lg'>
                                        <input type={"checkbox"} id="TikTok" name="tiktok" className="text-primary" onChange={(e) => handleChangeSoical(e)} />
                                        <label htmlFor="tiktok" className="inline-block ml-2 text-sm text-gray-800"><i className='fa-brands fa-tiktok'></i> TikTok</label>
                                    </div>
                                    <div className='sm:basis-1/5 basis-1/2 border p-2 bg-white rounded-lg'>
                                        <input type={"checkbox"} id="linkedin" name="linkedin" className="text-primary" onClick={(e) => handleChangeSoical(e)} />
                                        <label htmlFor="linkedin" className="inline-block ml-2 text-sm text-gray-800"><i className='fa-brands fa-linkedin'></i> Linkedin</label>
                                    </div>
                                    <div className='sm:basis-1/5 basis-1/2 border p-2 bg-white rounded-lg'>
                                        <input type={"checkbox"} id="youtube" name="youtube" className="text-primary" onClick={(e) => handleChangeSoical(e)} />
                                        <label htmlFor="youtube" className="inline-block ml-2 text-sm text-gray-800"><i className='fa-brands fa-youtube'></i> YouTube</label>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <button
                                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary rounded-lg hover:bg-black focus:outline-none focus:bg-black">
                                    Search
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="mt-3">
                        {role == 2 &&
                            isLoading ?
                            <div className='bg-fourth p-4 rounded-lg'>
                                <h4 className='text-xl font-bold px-4'>My Connected Influencers</h4>
                                <div className='flex justify-center'>
                                    <div role="status">
                                        <svg aria-hidden="true" className="w-8 h-8 mr-2 text-white animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>

                                </div>
                            </div> :
                            <>
                                {friendData.length > 0 ?
                                    <>
                                        {role == 2 &&
                                            <div className='bg-fourth p-4 rounded-lg'>
                                                <div className='flex justify-between'>
                                                    <h4 className='text-xl font-bold px-4'>My Connected Influencers</h4>
                                                    <button className='bg-secondary shadow-lg rounded-lg p-2 px-4 text-white hidden' id='hideBtn' onClick={(e) => { handleConnectHide(e) }}>
                                                        Hide
                                                    </button>
                                                    <button className='bg-secondary shadow-lg rounded-lg p-2 px-4 text-white' id='showBtn' onClick={(e) => { handleConnectShow(e) }}>
                                                        Show
                                                    </button>
                                                </div>
                                                <div id='connectedInfluencers' className='hidden'>
                                                    {friendData.map(obj => {
                                                        return (
                                                            <>
                                                                <div className="flex justify-center items-center mt-3">
                                                                    <div className="p-4 bg-white rounded-lg shadow-lg border w-full">
                                                                        <div className='grid sm:grid-cols-4 grid-cols-1 gap-4 w-full'>
                                                                            <div className='col-span-1'>
                                                                                <div className='flex items-center h-full'>
                                                                                    <div className=' profile-picture rounded-full border border-primary'>
                                                                                        <img src={obj.compressed_image ? imgApi + obj.compressed_image : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt="" className='' loading='lazy' />
                                                                                    </div>
                                                                                    <div className='ml-4'>
                                                                                        <p className='text-xl'><b>{obj.name ? obj.name : obj.first_name}</b></p>
                                                                                        <p><small className='text-xs'><i className="fa-solid fa-location-dot"></i>&nbsp;{obj.location}</small></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='sm:col-span-2 col-span-1 flex flex-row items-center sm:mt-0 mt-5 sm:block hidden'>
                                                                                <div className='grid sm:grid-cols-2 grid-cols-1 gap-6 w-full'>
                                                                                    <div className=''>
                                                                                        <p className='mb-2'>
                                                                                            <b>
                                                                                                Interests
                                                                                            </b>
                                                                                        </p>
                                                                                        <div className='grid 2xl:grid-cols-3 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-1 mb-2'>
                                                                                            {obj.interest.map(obj => {
                                                                                                return (
                                                                                                    <div className='p-2 mr-2 flex items-center h-full bg-fourth rounded-lg'>
                                                                                                        <p className='text-secondary text-xs text-center w-full'>
                                                                                                            {obj.interest}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className='mb-2'>
                                                                                            <b>
                                                                                                Social Media
                                                                                            </b>
                                                                                        </p>
                                                                                        <div className='grid grid-cols-5 mb-2 gap-2'>
                                                                                            {obj?.social_media?.length > 0 &&
                                                                                                Object.keys(obj?.social_media[0])?.map((oneKey, i) => {
                                                                                                    const value = obj?.social_media[0][oneKey];
                                                                                                    if (
                                                                                                        typeof value === 'string' && // Check if it's a string
                                                                                                        value !== '' &&
                                                                                                        value !== null &&
                                                                                                        value.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() !== 'na'
                                                                                                    ) {
                                                                                                        if (value.toLowerCase().includes(oneKey + '.com') === true) {
                                                                                                            return (
                                                                                                                <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-primary rounded-lg w-full border hover:border-primary">
                                                                                                                    <a href={createSocialMediaUrl(oneKey, extractUsernameFromUrl(obj?.social_media[0][oneKey]?.toLowerCase().replace('@', '')))} target="_blank" className='text-center w-full block'>
                                                                                                                        <i className={"fa-brands fa-" + oneKey + " text-fourth"}></i>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <div key={i} className="p-2 mr-2 flex items-center justify-center h-full bg-primary rounded-lg w-full border hover:border-primary">
                                                                                                                    <a href={createSocialMediaUrl(oneKey, obj?.social_media[0][oneKey]?.replace('@', ''))} target="_blank" className='text-center w-full block'>
                                                                                                                        <i className={"fa-brands fa-" + oneKey + " text-fourth"} ></i>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-span-1 flex flex-col justify-center items-end sm:mt-0 mt-5'>
                                                                                <div className=' p-1 rounded-lg text-center bg-secondary sm:w-[150px] w-full'>
                                                                                    <button className='text-white' onClick={() => { handleUserDetails(obj.userid, 1); secureLocalStorage.setItem("profile_view", 1) }}> View Profile</button>
                                                                                </div>
                                                                                <button
                                                                                    className="w-full p-1 mt-1 text-white rounded-lg bg-primary sm:w-[150px] w-full" onClick={() => { nav('/messages?name=' + (obj.name ? obj.name : obj.first_name).replace(' ', '_')) }}>
                                                                                    Message
                                                                                </button>
                                                                                {/* <div className='mt-1 p-1 rounded-lg text-white text-center bg-primary'>
                                                                                <Popup overlayStyle="w-10" trigger={() => <button className='' onClick={() => handleClickChangeSocketUrl(obj.userid)} id="connectBtn"> Connect </button>}
                                                                                    {...{ overlayStyle, contentStyle }}
                                                                                    onOpen={() => handleClickChangeSocketUrl(obj.userid)}
                                                                                    position={position}>
                                                                                    <h1 className="p-1"></h1>
                                                                                    <form>
                                                                                        <div>
                                                                                            <input disabled={true} value={"Hi, it would be great to collaborate"} className="rounded-l-lg p-1 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" onChange={() => { setMessage("Hi, it would be great to collaborate") }} />
                                                                                            {limit ? <button onClick={(e) => handleClickSendMessage(e)}
                                                                                                className="px-4 rounded-r-lg bg-primary text-white font-bold p-1 uppercase border-t border-b border-r"
                                                                                            >{readyState !== ReadyState.OPEN ? 'Loading' : 'Send'}</button> : <button disabled={true} className='px-4 rounded-r-lg bg-black text-white font-bold p-1 uppercase border-t border-b border-r' key={obj.userid}>You hit the limit for connection</button>}
                                                                                        </div>
                                                                                    </form>
                                                                                </Popup>
                                                                            </div> */}
                                                                            </div>
                                                                        </div>

                                                                        {/* {limit ? <button className='py-1 px-4 bg-primary btn-primary text-white rounded-lg' key={obj.user_id} onClick={() => { handleClickChangeSocketUrl(obj.userid) }}>Connect</button> : <button disabled={true} className='py-1 px-4 bg-black btn-primary text-white rounded-lg' key={obj.userid}>You hit the limit for connection</button>} */}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                    {friendData.length > 20 &&
                                                        <div className='py-4 flex justify-center'>
                                                            {/* <button className="px-4 rounded-lg m-3 bg-primary text-white font-bold p-1 uppercase border-t border-b border-r" onClick={() => setPage(1)}>CLR</button> */}
                                                            {/* <button className="px-4 rounded-lg m-3 bg-primary text-white font-bold p-1 uppercase border-t border-b border-r" onClick={() => setPage(page - 10)}>less</button> */}
                                                            <button className="px-4 rounded-lg m-3 bg-primary text-white font-bold p-1 uppercase border-t border-b border-r" onClick={() => setPage(page + 10)}>Show More</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </> :
                                    role == 2 &&
                                    <div className='bg-fourth p-4 rounded-lg'>
                                        <h4 className='text-xl font-bold px-4'>My Connected Influencers</h4>
                                        <div className='flex justify-center'>
                                            <p>You have no influencer connections</p>
                                        </div>
                                    </div>

                                }

                            </>
                        }
                    </div>
                    <div className="mt-3">
                        {isLoading ?
                            <div className='bg-fourth p-4 rounded-lg'>
                                <h4 className='text-xl font-bold px-4'>All Influencers</h4>
                                <div className='flex justify-center'>
                                    <div role="status">
                                        <svg aria-hidden="true" className="w-8 h-8 mr-2 text-white animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>

                                </div>
                            </div> : <>
                                <div className='bg-fourth p-4 rounded-lg'>
                                    <h4 className='text-xl font-bold px-4'>All Influencers</h4>
                                    {allData.map(obj => {
                                        return (
                                            <>
                                                <div className="flex justify-center items-center mt-3">
                                                    <div className="p-4 bg-white rounded-lg shadow-lg border w-full">
                                                        <div className='grid sm:grid-cols-4 grid-cols-1 gap-4 w-full'>
                                                            <div className='sm:col-span-1 col-span-2'>
                                                                <div className='flex items-center h-full'>
                                                                    <div className=' profile-picture rounded-full border border-primary'>
                                                                        <img src={obj.compressed_image ? imgApi + obj.compressed_image : 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png'} alt="" className='' loading='lazy' />
                                                                    </div>
                                                                    <div className='ml-4'>
                                                                        <p className='text-xl'><b>{obj.name ? obj.name : obj.first_name}</b></p>
                                                                        <p><small className='text-xs'><i className="fa-solid fa-location-dot"></i>&nbsp;{obj.location}</small></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='sm:col-span-2 col-span-1 flex flex-row items-center sm:mt-0 mt-5 sm:block hidden'>
                                                                <div className='grid sm:grid-cols-2 grid-cols-1 gap-6 w-full'>
                                                                    <div className=''>
                                                                        <p className='mb-2'>
                                                                            <b>
                                                                                Interests
                                                                            </b>
                                                                        </p>
                                                                        <div className='grid 2xl:grid-cols-3 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-1 mb-2'>
                                                                            {obj.interest.map(obj => {
                                                                                return (
                                                                                    <div className='p-2 mr-2 flex items-center h-full bg-fourth rounded-lg'>
                                                                                        <p className='text-secondary text-xs text-center w-full'>
                                                                                            {obj.interest}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    <div >
                                                                        <div className='flex mb-2'>
                                                                            <p className=''>
                                                                                <b>
                                                                                    Social Media
                                                                                </b>
                                                                            </p>&nbsp;
                                                                            {role == 2 &&
                                                                                <Tooltip
                                                                                    content="Connect witht the influencer to access social media"
                                                                                    placement="top"
                                                                                >
                                                                                    <i className="fa-solid fa-circle-question"></i>
                                                                                </Tooltip>
                                                                            }

                                                                        </div>
                                                                        <div className='grid grid-cols-5 mb-2 gap-2'>
                                                                            {obj?.social_media?.length > 0 &&
                                                                                Object.keys(obj?.social_media[0])?.map((oneKey, i) => {
                                                                                    const value = obj?.social_media[0][oneKey];
                                                                                    if (
                                                                                        typeof value === 'string' && // Check if it's a string
                                                                                        value !== '' &&
                                                                                        value !== null &&
                                                                                        value.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase() !== 'na'
                                                                                    ) {
                                                                                        if (value.toLowerCase().includes(oneKey + '.com') === true) {
                                                                                            return (
                                                                                                <div
                                                                                                    key={i}
                                                                                                    className="p-2 mr-2 flex items-center justify-center h-full bg-secondary rounded-lg w-full border hover:border-primary"
                                                                                                >
                                                                                                    <a target="_blank" className="text-center w-full block">
                                                                                                        <i className={'fa-brands fa-' + oneKey + ' text-fourth'}></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <div
                                                                                                    key={i}
                                                                                                    className="p-2 mr-2 flex items-center justify-center h-full bg-secondary rounded-lg w-full border hover:border-primary"
                                                                                                >
                                                                                                    <a className="text-center w-full block">
                                                                                                        <i className={'fa-brands fa-' + oneKey + ' text-fourth'}></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-span-1 flex flex-col justify-center items-end sm:mt-0 mt-5'>
                                                                <div className=' p-1 rounded-lg text-center bg-secondary sm:w-[150px] w-full'>
                                                                    <button className='text-white' onClick={() => { handleUserDetails(obj.userid, 0); secureLocalStorage.setItem("profile_view", 1) }}> View Profile</button>
                                                                </div>
                                                                {role == 2 ?
                                                                    limit ?
                                                                        <button
                                                                            className="w-full p-1 mt-1 text-white rounded-lg bg-primary sm:w-[150px] w-full" onClick={() => { setRecevier(obj.userid); handleConnectButton(obj.userid, (obj.name ? obj.name : obj.first_name)) }}>
                                                                            Connect
                                                                        </button>
                                                                        :
                                                                        <button
                                                                            className="w-full p-1 mt-1 text-white rounded-lg bg-primary sm:w-[150px] w-full" disabled={true}>
                                                                            Limit Reached {limit}
                                                                        </button>
                                                                    :
                                                                    <button
                                                                        className="w-full p-1 mt-1 text-white rounded-lg bg-primary sm:w-[150px] w-full" disabled={true}>
                                                                        Can't Connect
                                                                    </button>
                                                                }
                                                                {/* <div className='mt-1 p-1 rounded-lg text-white text-center bg-primary'>
                                                                <Popup overlayStyle="w-10" trigger={() => <button className='' onClick={() => handleClickChangeSocketUrl(obj.userid)} id="connectBtn"> Connect </button>}
                                                                    {...{ overlayStyle, contentStyle }}
                                                                    onOpen={() => handleClickChangeSocketUrl(obj.userid)}
                                                                    position={position}>
                                                                    <h1 className="p-1"></h1>
                                                                    <form>
                                                                        <div>
                                                                            <input disabled={true} value={"Hi, it would be great to collaborate"} className="rounded-l-lg p-1 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" onChange={() => { setMessage("Hi, it would be great to collaborate") }} />
                                                                            {limit ? <button onClick={(e) => handleClickSendMessage(e)}
                                                                                className="px-4 rounded-r-lg bg-primary text-white font-bold p-1 uppercase border-t border-b border-r"
                                                                            >{readyState !== ReadyState.OPEN ? 'Loading' : 'Send'}</button> : <button disabled={true} className='px-4 rounded-r-lg bg-black text-white font-bold p-1 uppercase border-t border-b border-r' key={obj.userid}>You hit the limit for connection</button>}
                                                                        </div>
                                                                    </form>
                                                                </Popup>
                                                            </div> */}
                                                            </div>
                                                        </div>

                                                        {/* {limit ? <button className='py-1 px-4 bg-primary btn-primary text-white rounded-lg' key={obj.user_id} onClick={() => { handleClickChangeSocketUrl(obj.userid) }}>Connect</button> : <button disabled={true} className='py-1 px-4 bg-black btn-primary text-white rounded-lg' key={obj.userid}>You hit the limit for connection</button>} */}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                                <div className='py-4 flex justify-center'>
                                    {/* <button className="px-4 rounded-lg m-3 bg-primary text-white font-bold p-1 uppercase border-t border-b border-r" onClick={() => setPage(1)}>CLR</button> */}
                                    {/* <button className="px-4 rounded-lg m-3 bg-primary text-white font-bold p-1 uppercase border-t border-b border-r" onClick={() => setPage(page - 10)}>less</button> */}
                                    <button className="px-4 rounded-lg m-3 bg-primary text-white font-bold p-1 uppercase border-t border-b border-r" onClick={() => { setPage(page + 10);  handleClick() }}>{buttonText}</button>
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Chat;
