import { useEffect, useState } from "react";
import Datepicker from "tailwind-datepicker-react"

const today = new Date();
const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

const options = {
	title: "Select Your Birthday",
	autoHide: true,
	todayBtn: false,
	clearBtn: false,
	maxDate: new Date(),
	minDate: new Date("1950-01-01"),
	theme: {
		background: "bg-white",
		icons: "",
		text: "",
		disabledText: "Min age is 18 years",
		input: "pl-10 focus:ring-primary focus:border-primary",
		inputIcon: "",
		selected: "bg-primary hover:bg-secondary",
	},
	icons: {
		// () => ReactElement | JSX.Element
		prev: () => <span><i className="fa-solid fa-arrow-left"></i></span>,
		next: () => <span><i className="fa-solid fa-arrow-right"></i></span>,
	},
	datepickerClassNames: "top-12",
	defaultDate: maxDate,
	language: "en",
}

const DatePicker = ({ labelName, inputName, inputValue, onChange, style, required, className, setSelectedDate }) => {

	const [show, setShow] = useState(false)
	const [error, setError] = useState(false)

	const handleChange = (selectedDate) => {
		const age = today.getFullYear() - selectedDate.getFullYear();

		if (age < 18) {
			setError(true)
			setSelectedDate(0);
		} else {
			setError(false)
			setSelectedDate(age);
		}
	}

	useEffect(() => {}, [error])

	const handleClose = (state) => {
		setShow(state)
	}

	return (
		<>
			<Datepicker showFooter={false}  options={options} onChange={handleChange} show={show} setShow={handleClose} />
			{ error == true ? <small className="text-red-500">You need to be above 18 years old</small>: ''}
			
		</>
	);
}


export default DatePicker;