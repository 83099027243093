import './inputField.style.scss';

const Checkbox = ({labelName, inputName, inputValue, onChange, style, required, checked, className, id}) => {
    
    return (
        <div className="flex items-center mb-4">
            <input id={id} type="checkbox" name={inputName} value={inputValue} onChange={onChange} checked={checked} required={required} className={"w-4 h-4 text-primary bg-white border-gray-300 rounded focus:ring-primary focus:ring-2" + className}/>
            <label htmlFor={id} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{labelName}</label>
        </div>
    );
    }

Checkbox.defaultProps = {
    required: false
}

export default Checkbox;