import { useState, useEffect, useContext } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useStepperContext } from "../../../contexts/StepperContext";
import Select from "../../../components/Select/Select";


function Charity({heading, subHeading, apiName, apiName2}){
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const { userData, setUserData } = useStepperContext();
    const [userCharity, setUserCharity] = useState(false);
    const [userSustain, setUserSustain] = useState(true);
    const [userSelect, setUserSelect] = useState("");
    const [ htmlContent, setHtmlContent ] = useState('');

    const charityOptions = [
        {
            'label': 'Please select your option',
            'value': '',
            'disabled' : false
        },
        {
            'label': 'Charitable',
            'value': 'charitable',
            'disabled' : false
        },
        {
            'label': 'Sustainable',
            'value': 'sustainable',
            'disabled' : false
        },
        {
            'label': 'None of the above',
            'value': 'none',
            'disabled' : false
        },
    ]


    const handleChange = (e) => {
        const { value } = e.target;

        if(value == 'charitable') {
            setUserCharity(true)
            document.getElementById('charity-or-sustain-reason').style.display = 'block'
        } else if (value == 'sustainable'){
            setUserSustain(true)
            document.getElementById('charity-or-sustain-reason').style.display = 'block'
        } else {
            document.getElementById('charity-or-sustain-reason').style.display = 'none'
        }
        setUserSelect(value)
    };

    const handleEditorChange = (state) => {
        setEditorState(state);
        setHtmlContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    
    useEffect(() => {
        setUserData({ ...userData, [apiName]:userCharity, [apiName2]: userSustain, ['reason']: htmlContent});
    }, [userCharity, htmlContent])

    return (
        <div className="w-full">
            <div className="w-full flex-1">
                <h4 className="text-center text-2xl">
                    <b>
                        {heading}
                    </b>
                </h4>
                <p className="text-center">
                    <small>
                        {subHeading}
                    </small>
                </p>
                <div className="mt-7">
                    <div className="bg-fourth p-6 border border-gray-200 rounded-lg shadow-lg w-full flex justify-center items-center step-card flex-col w-full">
                        <div className="w-full">
                            <Select
                                options={charityOptions}
                                onChange={handleChange}
                                inputValue={userSelect}
                                inputName={'charitable_or_sustainable'}
                            ></Select>
                        </div>
                        <div className="mt-4 w-full hidden" id="charity-or-sustain-reason">
                            <Editor
                                onEditorStateChange={handleEditorChange}
                                editorClassName="editor-class rounded-b-lg"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                    options: ['inline', 'blockType']
                                }}
                            ></Editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Charity;