import { useEffect, useState, useContext } from 'react';
import TokenService from '../../API/token.service';
import useAxios, { imgApi } from '../../API/Api.config';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link, useNavigate} from 'react-router-dom';
import api from '../../API/axiosConfig';
import { userContext } from "../../components/Context/UserContext";
import hero from '../../assets/images/blog/hero.jpg';
import './News.style.scss';


const NewsPage = () => {
    document.title = "Blog | PROMOTE"

  // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }
    

    // const token = TokenService.getLocalAccessToken()
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const {role}  = useContext(userContext)

    const { response, isLoading, status, error } = useAxios({
        method: "get",
        url: 'news_page/',
        // headers: {
        //     "Authorization": `Token ${token}`
        // }
    });



    useEffect(() => {
        if (response !== null) {
            setData(response)
            console.log(response)

        } else if(status === 401){
            nav('/login')
        }
    }, [status, response]);


    return (
        <>
            <Header />
                <div id='News'>
                    <section id='section1' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ hero +")"}}>
                        <div className='container mx-auto h-full'>
                            <div className='flex flex-col justify-center items-center h-full w-full'>
                                <div className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-lg">
                                    <h5 className="text-4xl tracking-tight font-extrabold text-center text-primary">Blog</h5>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id='section2'>
                        <div className='container mx-auto px-4 sm:px-0'>
                            <h2 className='mb-4 text-3xl tracking-tight font-medium text-center text-black'>Our Latest Blogs</h2>
                            <div className='flex flex-row flex-wrap'>
                                {data.map(obj => {
                                    return (
                                    <div key={obj.id} className='sm:basis-1/3 basis-full flex flex-col items-center pt-10 pb-10'>
                                        <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md w-full">
                                            <div className='blog-img' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("+ imgApi + obj.image +")"}}>
                                            </div>
                                            <div className="p-5">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-black">{obj.title}</h5>
                                                <div className="mb-3 font-normal text-secondary blog-text break-words" dangerouslySetInnerHTML={{ __html: obj.summary }}/>
                                                <Link to={`/article/${obj.title.toLowerCase().replace(/\s+/g, '-')}`} state={{
                                                        id: obj.id,
                                                        title: obj.title,
                                                        date: obj.date,
                                                        image: obj.image,
                                                        descriptions: obj.descriptions
                                                    }} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-secondary">
                                                    Read more
                                                    <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                </Link>
                                            </div>
                                        </div>
                                        {/* <div key={obj.id} className="flex flex-row shadow-xl divide-y divide-dashed text-justify box-content rounded-lg overflow-auto border-black h-auto w-auto m-5 p-4 border-0">
                                            <div className="mr-3">
                                                <img src={imgApi + obj.image} alt={obj.image} />
                                            </div>
                                            <div>
                                                <div>{obj.title}</div>
                                                    <div dangerouslySetInnerHTML={{ __html: obj.summary }} />
                                                    <Link to={"/article/"} state={{
                                                        id: obj.id,
                                                        title: obj.title,
                                                        image: obj.image,
                                                        descriptions: obj.descriptions
                                                    }}>
                                                    open
                                                </Link>
                                            </div>
                                        </div> */}
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            <Footer/>
        </>
    );
}

export default NewsPage;