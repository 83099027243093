import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const DynamicTrafficChart = ({ labels, seriesData, colors, chartLabel }) => {
    const [chartData, setChartData] = useState({
        series: seriesData,
    });

    // Get the chart options dynamically
    const getChartOptions = () => ({
        series: chartData.series,
        colors: colors || ["#008e95", "#222831"], // Default colors
        chart: {
            height: 250,
            width: "100%",
            type: "donut",
            background: "#f9f9f9"
        },
        stroke: {
            colors: ["transparent"],
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontFamily: "'Montserrat', sans-serif",
                            offsetY: 0,
                        },
                        total: {
                            showAlways: true,
                            show: true,
                            label: "", // Default label
                            fontFamily: "Montserrat', sans-serif",
                            fontWeight: "600",
                            fontSize: '12px',
                            offsetY: 0,
                            formatter: function (w) {
                                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                return new Intl.NumberFormat().format(sum);
                            },
                        },
                        value: {
                            show: true,
                            fontFamily: "'Montserrat', sans-serif",
                            offsetY: 10,
                            fontWeight: "600",
                            fontSize: '25px',
                            formatter: function (value) {
                                return new Intl.NumberFormat().format(value);
                            },
                        },
                    },
                    size: "75%",
                },
            },
        },
        grid: {
            padding: {
                top: -2,
            },
        },
        labels: labels || ["Label 1", "Label 2"], // Default labels
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: "bottom",
            fontFamily: "'Montserrat', sans-serif",
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return new Intl.NumberFormat().format(value);
                },
            },
        },
        xaxis: {
            labels: {
                formatter: function (value) {
                    return new Intl.NumberFormat().format(value);
                },
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
    });

    return <Chart options={getChartOptions()} series={chartData.series} type="donut" height={250} />;
};

export default DynamicTrafficChart;
