import { useState, createContext, useMemo } from "react";



export const userContext = createContext()

const UserContext = ({ children }) => {
    const [role, setRole] = useState(null);
    const addRole = (role)=>{
        setRole(role)
    }
    return (
        <userContext.Provider value={{role, addRole}}>
            {children}
        </userContext.Provider>
    );
}

export default UserContext;