import { useState, useRef, useEffect } from 'react';
import Compressor from 'compressorjs';
import { imgApi } from '../../API/Api.config';
import './dropzone.style.scss';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function Dropzone({ setpp, oldImage, setap }) {
    // drag state
    const [dragActive, setDragActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [img, setImg] = useState("");
    const MySwal = withReactContent(Swal)

    // ref
    const inputRef = useRef(null);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    // This function will be called when the image finishes loading
    const handleImageLoad = (e) => {
        if (e.target.src !== 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png') {
            setIsLoading(false);
        } else {
            // setIsLoading(true);
        }
    };

    const validateFileSize = (file) => {
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        if (file.size > maxSize) {
            MySwal.fire({
                position: 'top',
                toast: true,
                icon: 'error',
                title: 'File size more than 5MB',
                showConfirmButton: false,
                timer: 3000,
            })
            return false;
        }
        return true;
    };

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = async function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {

            const file = e.dataTransfer.files[0]
            
            if (!validateFileSize(file)) {
                
                return; // Do not proceed if file size is invalid
                
            } else {
                
                setImg(URL.createObjectURL(e.dataTransfer.files[0]))
                let compressedFile = null;
                const compressed = new Compressor(file, {
                    quality: 0.6,

                });
                const base64 = await convertBase64(compressed.file)
                if (setpp) {
                    setpp(base64)
                } else {
                    setap(base64)
                }
            }

        }
    };

    // triggers when file is selected with click
    const handleChange = async function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            if (!validateFileSize(file)) {

                return; // Do not proceed if file size is invalid

            } else {

                setImg(URL.createObjectURL(e.target.files[0]))
                let compressedFile = null;
                const compressed = new Compressor(file, {
                    quality: 0.6,
    
                });
                const base64 = await convertBase64(compressed.file)
                console.log(typeof base64)
                if (setpp) {
                    setpp(base64)
                } else {
                    setap(base64)
                }
            }
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    useEffect(() => { }, [img])


    return (
        <div className="flex flex-col items-center justify-center">
            <div id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept=".jpg, .jpeg" size="5242880" />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                    <div>
                        <p>Drag and drop your file here or</p>
                        <button className="upload-button" onClick={onButtonClick}>Upload a file</button><br />
                        <small className='text-2xs'>Box dimensions allowed<br /> (eg: 512x512, 128x128)<br /> Maximum file size 5MB</small>
                    </div>
                </label>
                {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            </div>
            <div className="flex flex-col justify-center items-center img-div">
                <p className='text-center'>
                    Image preview
                </p>
                <div className='profile-img flex flex-col justify-center items-center'>
                    <img
                        src={img ? img : oldImage ? oldImage : 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png'}
                        alt=""
                        className='w-full'
                        onLoad={(e) => { handleImageLoad(e) }}
                    />
                    {isLoading && (
                        (img && (
                            <div role="status mt-3">
                                <svg aria-hidden="true" className="w-4 h-4 mt-2 text-gray-200 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dropzone;