import { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";

import { ApiUrl } from '../../API/Api.config';
import withReactContent from 'sweetalert2-react-content'
// import Cookies from 'universal-cookie';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import MobileMain from '../../assets/images/home/mobile-main.jpg';
import Service1 from '../../assets/images/home/service1.jpg';
import Service2 from '../../assets/images/home/service2.jpg';
import Service3 from '../../assets/images/home/service3.jpg';
import Service4 from '../../assets/images/home/service4.jpg';
import CaseStudy1logo from '../../assets/images/new-case-study/casestudy-1/logo-nobg.png';
import CaseStudy1img from '../../assets/images/new-case-study/casestudy-1/image.jpg';
import CaseStudy1video from '../../assets/images/new-case-study/casestudy-1/video.mp4';
import CaseStudy2logo from '../../assets/images/new-case-study/casestudy-2/logo.png';
import CaseStudy2img from '../../assets/images/new-case-study/casestudy-2/image.jpeg';
import CaseStudy2video from '../../assets/images/new-case-study/casestudy-2/video.mp4';
import CaseStudy3logo from '../../assets/images/new-case-study/casestudy-3/logo.png';
import CaseStudy3img from '../../assets/images/new-case-study/casestudy-3/image.jpeg';
import CaseStudy3video from '../../assets/images/new-case-study/casestudy-3/video.mp4';
import video from '../../assets/images/home/promote.mp4';
import Logo8 from '../../assets/images/home/company/logo-8.png'
import Logo9 from '../../assets/images/home/company/logo-9.png'
import Logo10 from '../../assets/images/home/company/logo-10.svg'
import Logo11 from '../../assets/images/home/company/logo-11.png'
import Logo12 from '../../assets/images/home/company/logo-12.png'
import Logo13 from '../../assets/images/home/company/logo-13.png'
import Logo14 from '../../assets/images/home/company/logo-14.png'
import Logo15 from '../../assets/images/home/company/logo-15-nobg.png'
import Logo16 from '../../assets/images/home/company/logo-16.png'
import Logo17 from '../../assets/images/home/company/logo-17.png'
import Logo18 from '../../assets/images/home/company/logo-18.png'
import Logo19 from '../../assets/images/home/company/logo-19.png'
import Logo20 from '../../assets/images/home/company/logo-20.png'
import Logo21 from '../../assets/images/home/company/logo-21.webp'
import videoBg from '../../assets/images/home/video-bg.jpg'
import logowhite from '../../assets/images/logowhite-video.png';
import { Link } from 'react-router-dom';
import './Home.style.scss';
import CookieConsent from "react-cookie-consent";


const StartupHome = () => {
    document.title = "PROMOTE | Influencer promotions arranged for your hospitality venue"

    // const cookies = new Cookies();
    const [isOpen, setIsOpen] = useState(false)

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    async function emailOffer(data) {
        try {
            axios.post(ApiUrl + `offer_email/`, data, { headers: { 'Accept': 'application/json' } })
                .then(function (response) {

                }).then(willSearch => {
                    if (willSearch) {
                        // swal.close()
                        window.location.reload(false);
                    }
                })
                .catch(error => {
                    if (error.request.status) {
                        // swal({
                        //     title: "Confirmation",
                        //     text: "Please try again later",
                        //     icon: "error",
                        //     className: "app-popup",
                        //     dangerMode: true,
                        // })
                    }
                });
        } catch (e) {
            console.log(e)
        }
    }

    const { setError, register, handleSubmit, formState: { errors } } = useForm();

    const handleSubmitForm = (data) => {
        emailOffer(data)

    }

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const [videoLoading, setVideoLoading] = useState(true);
    const [introTextOpacity, setIntroTextOpacity] = useState(1);  // Opacity state for text and black screen
    const [logoOpacity, setLogoOpacity] = useState(1);           // Video playback state
    const [showIntro, setShowIntro] = useState(true);             // Control when to show the intro section


    const handlePlayPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleMuteUnmute = () => {
        videoRef.current.muted = !videoRef.current.muted;
        setIsMuted(videoRef.current.muted);
    };

    useEffect(() => {
        // Fade out the black screen and text first
        const fadeOutTextTimeout = setTimeout(() => {
            setIntroTextOpacity(0); // Start fading out the text and black screen

            setTimeout(() => {
                setShowIntro(false);  // Hide the black screen and text entirely
                videoRef.current.play(); // Start video playback
                setIsPlaying(true);      // Set video to playing state
            }, 750);  // Wait for the text to fully fade out (2.75s) before starting the video

            // Fade out the logo a bit later (1 second after text fade)
            const logoFadeTimeout = setTimeout(() => {
                setLogoOpacity(0);  // Start fading out the logo
            }, 1000);  // 4 seconds total (text fades first, then logo fades)

            return () => {
                clearTimeout(fadeOutTextTimeout);
                clearTimeout(logoFadeTimeout);
            };
        }, 1000);  // Keep the text and black screen visible for 3 seconds before fading

    }, []);


    // useEffect(() => {
    //     if (cookies.get('visit') === undefined) {
    //         cookies.set('visit', 'visited');
    //         setIsOpen(true)
    //     }
    // }, [])
    useEffect(() => { }, [isOpen])

    return (
        <>
            <Header />
            <div id='startupHome'>
                <section id='section1-mobile' className='sm:hidden block'>
                    <div className='bg-img flex justify-center items-center' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" + MobileMain + ")", backgroundSize: "cover" }}>
                        <h1 className="font-bold text-center">Influencer promotions arranged for your hospitality venue</h1>
                    </div>
                    <div className="relative w-full h-auto">
                        <video
                            ref={videoRef}
                            muted
                            loop
                            controls
                            autoPlay
                            id="vid"
                            className="w-full h-auto"
                            onWaiting={() => setVideoLoading(true)}  // Video is waiting (buffering)
                            onCanPlayThrough={() => setVideoLoading(false)} // Video can play completely without interruption
                            onCanPlay={() => setVideoLoading(false)} // Video can start playing
                        >
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {/* Loading Icon */}
                        {videoLoading && (
                            <div className="absolute inset-0 flex items-center justify-center pointer-events-none bg-black bg-opacity-50">
                                <div className="loader"></div> {/* You can replace this with any loading spinner */}
                            </div>
                        )}
                    </div>

                </section>
                <section id='section1' className='section hidden sm:block'>
                    <div className='container-fluid mx-auto h-full'>
                        <div className='flex flex-col justify-start items-end h-full w-full'>
                            <div className="video-container relative w-full h-screen">
                                <video ref={videoRef} muted={isMuted} loop id='vid' className="w-full h-full object-fill">
                                    <source src={video} type="video/mp4" />
                                </video>

                                {/* Overlay for intro text and black background */}
                                {showIntro && (
                                    <div
                                        className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center z-20"
                                        style={{
                                            backgroundColor: 'black',  // Black screen for the background
                                            opacity: introTextOpacity,  // Controls fade-out for black screen and text
                                            transition: 'opacity 3s ease-in-out',  // Smooth fade-out effect
                                        }}
                                    >
                                        <h1 className="font-bold text-white text-center video-text">
                                            Influencer Promotions For Hospitality Venues
                                        </h1>
                                    </div>
                                )}

                                {/* Separate Logo Section */}
                                <div
                                    className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-30"  // Higher z-index to stay above video
                                    style={{
                                        opacity: logoOpacity,  // Control the logo's opacity separately
                                        transition: 'opacity 2s ease-in-out',  // Smooth fade-out transition for the logo
                                    }}
                                >
                                    <img
                                        src={logowhite}
                                        className="logo mb-10"
                                        alt="Promote Logo"
                                    />
                                </div>

                                {/* Video controls */}
                                <div className="controls absolute bottom-4 left-4 flex space-x-2 sm:block hidden z-40">
                                    {isPlaying ? (
                                        <button onClick={handlePlayPause} className="control-button bg-primary p-4">
                                            <i className="fa-solid fa-pause text-white"></i>
                                        </button>
                                    ) : (
                                        <button onClick={handlePlayPause} className="control-button bg-black p-4">
                                            <i className="fa-solid fa-play text-white"></i>
                                        </button>
                                    )}
                                    {isMuted ? (
                                        <button onClick={handleMuteUnmute} className="control-button bg-black p-4">
                                            <i className="fa-solid fa-volume-xmark text-white"></i>
                                        </button>
                                    ) : (
                                        <button onClick={handleMuteUnmute} className="control-button bg-primary p-4">
                                            <i className="fa-solid fa-volume-high text-white"></i>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='logo-banner'>
                    <div className='flex justify-center items-center'>
                        <div className='container'>
                            <div className='grid grid-cols-11 gap-4'>
                                <div className='sm:col-span-2 col-span-4 flex flex-col justify-center text-start'>
                                    <p className='text-left'><span className='font-bold'>Brands that</span><br></br><span className='text-primary'>trust us</span></p>
                                </div>
                                <div className='sm:col-span-2 col-span-7'>
                                    <div className='relative marquee-container sm:none flex space-x-6'>
                                        <div className="marquee flex justify-between space-x-16">
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo8} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo10} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo18} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo9} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo11} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo12} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo13} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo14} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo15} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo16} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo17} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo19} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo20} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo21} alt="Promote.io trusted brand" className='logo-img' /></span>
                                        </div>
                                        <div className=" marquee2 flex justify-between space-x-16">
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo8} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo10} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo9} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo11} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo12} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo13} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo14} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo15} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo16} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo17} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo18} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo19} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo20} alt="Promote.io trusted brand" className='logo-img' /></span>
                                            <span className='flex items-center justify-center carousel-img'><img src={Logo21} alt="Promote.io trusted brand" className='logo-img' /></span>
                                        </div>
                                    </div>
                                    <div class="relative z-10 left-0 top-[-100px] w-2/12 h-full bg-gradient-to-r from-white hidden | md:block"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section2'>
                    <div className='container mx-auto'>
                        <div className='flex flex-col'>
                            <div className='grid grid-cols-3 gap-x-10 w-full'>
                                <div className='sm:col-span-1 col-span-3'>
                                    <h2 className='text-center text-primary text-start cols-span-1'>
                                        <span className='text-secondary'>Our</span>
                                        <br></br>
                                        <b>
                                            Services
                                        </b>
                                    </h2>
                                    <p className=''>
                                        We connect your brand with the right influencers to create impactful and authentic content, enhancing your reach and engagement.
                                    </p>
                                </div>
                                <div className='grid grid-cols-2 gap-5 sm:col-span-2 col-span-3 sm:mt-0 mt-8'>
                                    <div className='card bg-white shadow sm:col-span-1 col-span-2 sm:p-0 p-4' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" + Service1 + ")" }}>
                                        <div className='flex justify-center items-center h-full'>
                                            <p className='text-white text-center'>
                                                <b>
                                                    Influencer events
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='card bg-white shadow sm:col-span-1 col-span-2 sm:p-0 p-4' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" + Service2 + ")" }}>
                                        <div className='flex justify-center items-center h-full'>
                                            <p className='text-white text-center'>
                                                <b>
                                                    Solo influencer visits
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='card bg-white shadow sm:col-span-1 col-span-2 sm:p-0 p-4' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" + Service3 + ")" }}>
                                        <div className='flex justify-center items-center h-full'>
                                            <p className='text-white text-center'>
                                                <b>
                                                    Influence from home
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='card bg-white shadow sm:col-span-1 col-span-2 sm:p-0 p-4' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" + Service4 + ")" }}>
                                        <div className='flex justify-center items-center h-full'>
                                            <p className='text-white text-center'>
                                                <b>
                                                    User Generated Content
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section3' className='section bg-primary'>
                    <div className='container mx-auto '>
                        <div className='flex justify-center items-end flex-col'>
                            <h2 className='text-center text-fourth w-full'><b><span className='text-secondary'>Case</span> Studies</b></h2>
                            <div className='grid grid-cols-5 grid-rows-2 w-full mt-10 gap-5 sm:h-[700px] h-full'>
                                <div className='bg-white rounded-lg shadow-lg sm:col-span-3 col-span-5 home-case-study-container'>
                                    <div className='h-full rounded-lg flex justify-start items-end' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75)), url(" + CaseStudy1img + ")", backgroundSize: "100%", backgroundPosition: 'center' }}>
                                        <div className='flex space-x-4 w-full'>
                                            <div className='img-container bg-white rounded-bl-lg rounded-tr-lg flex justify-center items-center'>
                                                <img src={CaseStudy1logo} alt="Promote Case Study 1" className='home-case-study' />
                                            </div>
                                            <div className='flex flex-col sm:flex-row w-full sm:items-end items-start sm:justify-between justify-end py-2 sm:py-0'>
                                                <div className='sm:py-3'>
                                                    <span className='rounded-full text-2xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                    <p className='font-bold text-white mt-1'>Byron Burgers</p>
                                                </div>
                                                <div className='sm:self-end'>
                                                    <p className='text-white sm:p-3'>
                                                        <Link to={`/case-study/byron-burgers`} className="sm:flex sm:justify-center sm:items-center gap-2" state={{
                                                            client: 'Byron Burgers',
                                                            image: CaseStudy1img,
                                                            logo: CaseStudy1logo,
                                                            overview: 'Byron are a UK based burger brand with 9 stores across the country. The summer menu launch event for Byron’s venue in Covent Garden was the 1st menu launch organised by Promote for Byron Burgers. Byron requested influencers based in various parts of London to attend the event. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fashion and lifestyle as this appeals to their varied customer base. The high number of views meant that Byron were trending on social media and highly visible following the event.',
                                                            service: 'Influencer Events',
                                                            location: 'United Kingdom',
                                                            creators: '49',
                                                            likes: '9,742',
                                                            comments: '547',
                                                            shares: '458',
                                                            viewsLabel: ['Instagram', 'Tiktok'],
                                                            views: [397538, 44171],
                                                            viewsColor: ["#008e95", "#222831"],
                                                            contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                                            content: [28, 31, 2, 19],
                                                            contentColor: ["#008e95", "#222831", "#A9A9A9", "#FFFFFF"],
                                                            video: CaseStudy1video,
                                                            horizontalVideo: 0,
                                                            bgPosition: '-350px'
                                                        }}>
                                                            View Case Study <i class="fa-solid fa-arrow-right"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white rounded-lg shadow-lg sm:col-span-3 col-span-5 home-case-study-container'>
                                    <div className='h-full rounded-lg flex items-end' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75)), url(" + CaseStudy2img + ")", backgroundSize: "cover", backgroundPosition: 'center' }}>
                                        <div className='flex space-x-4 w-full'>
                                            <div className='img-container bg-white rounded-bl-lg rounded-tr-lg flex justify-center items-center'>
                                                <img src={CaseStudy2logo} alt="Promote Case Study 1" className='home-case-study' />
                                            </div>
                                            <div className='flex flex-col sm:flex-row w-full sm:items-end items-start sm:justify-between justify-end py-2 sm:py-0'>
                                                <div className='sm:py-3'>
                                                    <span className='rounded-full text-2xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                    <p className='font-bold text-white mt-1'>Marco Pierre White</p>
                                                </div>
                                                <div className='sm:self-end'>
                                                    <p className='text-white sm:p-3'>
                                                        <Link to={`/case-study/marco-pierre-white`} className="sm:flex sm:justify-center sm:items-center gap-2" state={{
                                                            client: 'Marco Pierre White',
                                                            image: CaseStudy2img,
                                                            logo: CaseStudy2logo,
                                                            overview: 'Marco Pierre White are a UK based brand with 18 steakhouse bar & grill locations across the country. The summer menu launch event for MPW’s flagship Birmingham venue located within The Cube Hotel was the 1st menu launch organised by Promote for Marco Pierre White Restaurants. MPW requested influencers based in various parts of Birmingham to attend the event. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fashion and lifestyle as this appeals to their varied customer base. The high number of views meant that Marco Pierre White Restaurants were trending on social media and highly visible following the event.',
                                                            service: 'Influencer Events',
                                                            location: 'United Kingdom',
                                                            creators: '21',
                                                            likes: '6,219',
                                                            comments: '321',
                                                            shares: '441',
                                                            viewsLabel: ['Reel', 'Tiktok', 'Static Grid Post'],
                                                            views: [164393, 19340, 9670],
                                                            viewsColor: ["#008e95", "#222831", "#A4243B"],
                                                            contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                                            content: [12, 10, 2, 13],
                                                            contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                                            video: CaseStudy2video,
                                                            horizontalVideo: 0,
                                                            bgPosition: 'center'

                                                        }}>
                                                            View Case Study <i class="fa-solid fa-arrow-right"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white rounded-lg shadow-lg sm:col-span-2 col-span-5 sm:col-start-4 sm:row-span-2 sm:row-start-1 home-case-study-container'>
                                    <div className='h-full rounded-lg flex items-end' style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.75)), url(" + CaseStudy3img + ")", backgroundSize: "cover", backgroundPosition: 'top' }}>
                                        <div className='flex space-x-4 w-full'>
                                            <div className='img-container bg-white rounded-bl-lg rounded-tr-lg flex justify-center items-center'>
                                                <img src={CaseStudy3logo} alt="Promote Case Study 1" className='home-case-study' />
                                            </div>
                                            <div className='flex flex-col w-full items-start justify-start sm:py-0 py-2'>
                                                <div className=''>
                                                    <span className='rounded-full text-2xs bg-primary text-white py-1 px-3'>Influencer Events</span>
                                                    <p className='font-bold text-white mt-1'>T4 Elephant and Castle</p>
                                                </div>
                                                <div className=''>
                                                    <p className='text-white'>
                                                        <Link to={`/case-study/t4-elephant-and-castle`} className="sm:flex sm:justify-center sm:items-center gap-2" state={{
                                                            client: 'T4 Elephant and Castle',
                                                            image: CaseStudy3img,
                                                            logo: CaseStudy3logo,
                                                            overview: 'T4 is a global bubble tea company that has over 60 stores in the UK. Promote has organised 6 influencer events for T4’s new store openings. The influencer content focused on T4\’s extensive menu, bubble tea creation, their reactions and the location of the new store openings. It was important that the influencers had a wide range of interests from food and restaurant reviewers to fitness and healthy lifestyle as this appeals to their varied customer base. The high number of views at each event meant that each T4 store was trending on social media and highly visible following the events.',
                                                            service: 'Influencer Events',
                                                            location: 'United Kingdom',
                                                            creators: '28',
                                                            likes: '5,496',
                                                            comments: '257',
                                                            shares: '25',
                                                            viewsLabel: ['Instagram', 'Tiktok'],
                                                            views: [73158, 48772],
                                                            viewsColor: ["#008e95", "#222831", "#A4243B"],
                                                            contentLabel: ["Reel", "Insta Stories", "Grid Post", "Tiktok"],
                                                            content: [23, 28, 1, 2],
                                                            contentColor: ["#008e95", "#222831", "#ABA8B2", "#A4243B"],
                                                            video: CaseStudy3video,
                                                            horizontalVideo: 1,
                                                            bgPosition: 'center'

                                                        }}>
                                                            View Case Study <i class="fa-solid fa-arrow-right"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-end mt-10'>
                                <a href="/case-study" className="inline-flex items-center justify-center rounded-lg h-full py-2 px-3 text-2xl font-medium text-center text-white  hover:bg-fourth hover:text-black focus:ring-4 focus:outline-none">
                                    <b>More Case Studies</b>
                                    <svg aria-hidden="true" className="ml-2 -mr-1 w-10 h-" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <CookieConsent buttonStyle={{ backgroundColor: "#008e95", fontSize: "13px" }}>This website uses cookies to enhance the user experience.</CookieConsent>
                <Footer />
            </div>
        </>
    );
}

export default StartupHome;