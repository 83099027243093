import { useState, useEffect } from "react";
import axios from "axios";
import TokenService from "./token.service";
import { useNavigate, useParams } from "react-router-dom";

export const ApiUrl = process.env.REACT_APP_API_URL
export const socketApiUrl = process.env.REACT_APP_SOCKER_API_URL
export const imgApi = process.env.REACT_APP_API_URL_MEDIA
export const profileImageLink = process.env.REACT_APP_API_URL_MEDIA_PROFILE

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.headers.common['Authorization'] = 'Token ' + TokenService.getLocalAccessToken()

const useAxios = (params) => {
  const nav = useNavigate()
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const token = TokenService.getLocalAccessToken()
  const reload = ()=>{ return fetchData() }
  const fetchData = async () => {
    setIsLoading(true);
    try {
      await axios.request(params)
      .then(res => {

        
        setResponse(res.data);
        setStatus(res.status)
        setError(null);
        
      }).catch(err => {
        setStatus(err.response.status);
        setError(true)
        if(err.response.status === 401){

          nav('/login/')
        }
      }).finally(()=>{
        setIsLoading(false)
      });

      
    } catch (err) {
      setError(true)
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return { response, error, isLoading, status, reload };
};



export default useAxios;